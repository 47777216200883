import styled from "styled-components"

// components
import { Benefits, CertificationInfo, Consulting, ContactBanner, Distinction, Overview } from "../../../components/SubPageCommonUI"

// images
import table01 from "../../../images/certification/table01.png"
import image01 from "../../../images/certification/type01-01.png"
import image02 from "../../../images/certification/type02-01.png"
import image02Mo from "../../../images/certification/mobile_type02-01.png"
import image03 from "../../../images/certification/type02-08.png"
import image03Mo from "../../../images/certification/mobile_type02-08.png"
import image04 from "../../../images/certification/type03-08.png"
import image04Mo from "../../../images/certification/mobile-type03-08.png"
import logo01 from "../../../images/certification/logo01.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"

// style
const Container = styled.div`
   & .table_wrap{
      & .table_img{
         width: 100%;
         border-bottom: 1px solid var(--subColor05);
      }
      & .list:not(:last-of-type){
         margin-bottom: 16px;
      }
   }
   & .examination{
      background-color: var(--subColor11);
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         width: auto;
         height: 300px;
      }
   }
   .how {
      .main_img{
         width: 100%;
         max-width: 1020px;
      }
   }
   @media screen and (max-width:1280px){
      .table_img_wrap{
         overflow-x: scroll;
         width: 100%;
         padding-bottom: 24px;
         &::-webkit-scrollbar {
            width: 100%;
            height: 3px;
         }
         &::-webkit-scrollbar-track {
            width: 114px;
            background-color: var(--subColor05);
         }
         &::-webkit-scrollbar-thumb { 
            background-color: var(--pointColor03);
         }
         &::-webkit-scrollbar-button {
            display: none;
         }
         .table_img{
            width: auto;
            height: 385px;
         }
      }
   }
`
interface MoItems01Props {
   bgColor: string;
}
const MoItems01 = styled.li<MoItems01Props>` 
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 40px;
   font-size: 14px;
   color: #fff;
   background-color: ${(props) => props.bgColor};
   &:not(:last-of-type){
      margin-bottom: 8px;
   }
`

const ExcellentProcurement = () => {

   const overViewInfo = {
      title: "우수조달제품인증",
      desc: "조달청 우수제품 제도는 조달물자의 품질향상을 위하여 96년에 도입하여 중소기업 및 초기 중견기업이 생산한 제품 중 <br class='xl:block hidden' />기술 및 품질이 우수한 제품을 대상으로 엄정한 평가를 통해 우수제품으로 지정하는 제도입니다.",
      logo: logo01,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "조달청",
            bgImg: icon07,
         },
         {
            title: "심사비용",
            desc: "없음",
            bgImg: icon08,
         },
         {
            title: "유효기간",
            desc: "지정일로부터 3년",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "최대 4회",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "약 3개월",
            bgImg: icon11,
         },
      ],
      cont_desc: "※ 제품의 품질 및 기술인증, 납품실적, 수의계약 가능 여부, 수출실적 등을 고려하여 최대 3년간 연장 (연장 조건 중복 불가)",
      sub_desc: "※ 4회 탈락 후 재신청 방법: <br class='xl:block hidden' />특허 개발 및 변경으로 처음부터 다시 신청 ",
   };
   const BenefitsData = [
      {
         desc: "수의계약을 통해 <br />공공기관 우선 공급",
      },
      {
         desc: "판로개척을 지속적으로 지원 <br class='xl:block hideen' />(해외시장 개척 지원 및 <br class='xl:block hideen' />우수제품총량 제작/배포)",
      },
      {
         desc: "우수조달물품에 대한 지원 <br class='xl:block hideen' />(우수조달 전시회 참여 및 홍보 인쇄물 제작 및 배포)",
      }
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <Distinction />
         <section className="how container xl:flex justify-between xl:pb-120 pb-60">
            <h3 className="xl:leading-48 xl:text-32 text-24 font-bold">
               우수조달제품인증 <br className="xl:block hidden" />
               합격하는 방법
            </h3>
            <img className="xl:block hidden main_img xl:mt-0 mt-32" src={image03} alt="우수조달제품인증 합격하는 방법" />
            <img className="xl:hidden main_img xl:mt-0 mt-32" src={image03Mo} alt="우수조달제품인증 합격하는 방법" />
         </section>
         <section className="container xl:flex xl:gap-56 xl:pb-120 pb-60">
            <h3 className="whitespace-nowrap xl:leading-48 xl:text-32 text-24 font-bold">
               해답은 바로 <br />
               발표에 있습니다
            </h3>
            <div className="xl:mt-0 mt-24">
               <div>
                  <strong className="leading-1em xl:text-22 text-14 font-semibold">※ 종래의 문제점</strong>
                  <p className="xl:leading-26 xl:text-18 text-14 mt-16">· 과거에는 파워포인트를 사용한 발표자료로 발표를 하여 매우 수월하게 발표가 가능했으나, 현재는 한글문서로 발표를 진행하기에 발표 난이도 상승</p>
               </div>
               <div className="mt-32">
                  <strong className="leading-1em xl:text-22 text-14 font-semibold">※ <span className="text-pointColor01">우수조달컨설팅</span>만의 차별화된 노하우</strong>
                  <p className="xl:leading-26 xl:text-18 text-14 mt-16">· 수만개 업체와의 발표 예행연습으로 어떤식으로 발표해야하는지에 대한 빅데이터가 쌓여있어 성공 확률 95% 보장</p>
               </div>
               <img className="xl:block hidden w-full xl:mt-80" src={image04} alt="해답은 바로 발표에 있습니다 참고" />
               <img className="xl:hidden w-full mt-40" src={image04Mo} alt="해답은 바로 발표에 있습니다 참고" />
               <strong className="text-center xl:text-22 text-14 xl:mt-80 mt-32">※ 23년간의 빅데이터 활용으로 어떻게 하면 합격하고 어떻게 하면 불합격하는지를 쉽게 알게 됨</strong>
            </div>
         </section>
         <CertificationInfo
            info={CertificationData}
         />
         <section className="table_wrap container xl:pt-80 pt-60 xl:pb-80 pb-60">
            <h3 className="leading-1em xl:text-32 text-24 font-bold">지정 대상</h3>
            <div className="table_img_wrap">
               <img className="table_img xl:mt-48 mt-24" src={table01} alt="지정 대상 표" />
            </div>
            <ul className="mt-24">
               <li className="list leading-1em xl:text-16 text-12 text-subColor03">1&#41; 신제품, 신기술제품: 최초 인증일로부터 3년 이내&#40;종합평가서 필요&#41;</li>
               <li className="list leading-1em xl:text-16 text-12 text-subColor03">2&#41; 특허제품: 등록 후 7년 이내&#40;특허등록원부 및 등록공보 필요&#41;</li>
               <li className="list leading-1em xl:text-16 text-12 text-subColor03">3&#41; 실용신안제품: 등록 후 3년 이내&#40;실용 신안 등록원부 및 등록공보 필요&#41;</li>
               <li className="list leading-1em xl:text-16 text-12 text-subColor03">4&#41; 국제&#40;해외&#41;특허, 출원 중인 특허실용신안은 신청 불가&#40;심사 제외&#41;</li>
               <li className="list leading-1em xl:text-16 text-12 text-subColor03">5&#41; 혁신제품: 성공판정을 받은 후로부터 3년 이내</li>
            </ul>
         </section>
         <section className="examination xl:pt-80 xl:pb-80 pt-60 pb-60">
            <div className="container xl:flex justify-between">
               <h3 className="title leading-1em xl:text-32 text-24 font-bold">심사절차</h3>
               <img className="main_img xl:block hidden" src={image01} alt="심사절차" />
               <div className="xl:hidden mt-24">
                  <ul className="mo_items_wrap">
                     <MoItems01 bgColor="#75c5f5">신청접수&#40;온라인접수&#41;</MoItems01>
                     <MoItems01 bgColor="#48afec">신청제품공개 및 의견서 접수</MoItems01>
                     <MoItems01 bgColor="#2ea4e9">1차 심사 진행</MoItems01>
                     <MoItems01 bgColor="#1592dc">1차 심사 결과 보고</MoItems01>
                     <MoItems01 bgColor="#1592dc">1차 심사 결과 제품 공개 및 의견접수</MoItems01>
                  </ul>
                  <ul className="mo_items_wrap mt-40">
                     <MoItems01 bgColor="#0285d1">생산 현장조사</MoItems01>
                     <MoItems01 bgColor="#067abe">2차 심사 진행</MoItems01>
                     <MoItems01 bgColor="#0072b5">우수제품 지정</MoItems01>
                     <MoItems01 bgColor="#006aa8">지정 증서 수여</MoItems01>
                     <MoItems01 bgColor="#005c92">계약체결 • 규격추가 • 기간연장</MoItems01>
                  </ul>
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <Consulting
            imgSrc={image02}
            moImgSrc={image02Mo}
         />
         <ContactBanner />
      </Container >
   )
}

export default ExcellentProcurement;