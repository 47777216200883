import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo from "../../../images/certification/logo21.png"
import image01 from "../../../images/certification/type03-03.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import table04 from "../../../images/certification/table04.png"

// style
const Container = styled.div`
   & .target_wrap{
      & .title{
         white-space: nowrap;
      }
      & .item_wrap{
         width: 100%;
      }
   }
   & .examination{
      background-color: var(--subColor11);
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         width: 100%;
         height: auto;
      }
   }
   & .benefits{
    background-color: var(--subColor11);
   }
   @media screen and (max-width:1280px){
      .field{
         .main_img{
            width: auto;
            height: 200px;
         }
      }
      .procedure{
         .main_img{
            width: auto;
            height: 900px;
            max-height: calc(100vh - 60px);
         }
      }
   }
`

const ExcellenceCert = () => {

   const overViewInfo = {
      title: "우수조달공동상표인증",
      desc: "5 이상의 중소기업자가 판매활동을 강화하기 위하여 개발·보유한 공동상표로서 <br class='xl:block hidden' />기술 및 품질인증 등이 관련 규정에서 정하는 기준을 충족하는지 실태조사 및 지정심사 등을 거쳐 조달청장이 ‘우수조달공동상표 물품’으로 지정한 제품입니다.",
      logo: logo,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "조달청/ 우수제품구매과",
            bgImg: icon07,
         },
         {
            title: "심사비용",
            desc: "없음",
            bgImg: icon08,
         },
         {
            title: "유효기간",
            desc: "계약기간 3년",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "접수 마감일로부터 약 3개월 소요",
            bgImg: icon11,
         },
      ],
   };

   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "5 이상의 중소기업자가 참여하여 개발·보유한 공동상표를 상표법에 의하여 단체표장을 등록한 대표법인",
                  "참여기업의 40% 이상은 기술인증과 품질인증을 보유하여야 하고, 참여기업의 40% 이상은 소기업이어야 함",
                  "기술인증 미보유 업체는 통상실시권을 보유하여야 함",
                  "기술인증 또는 품질인증 보유비율은「우수조달공동상표 물품 지정 및 관리 규정」의 [별지 제1호의 붙임 2] 법인 평가지표별 평가방법에 의함",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  `국제(해외)특허, 출원중인 특허·실용신안은 신청불가(심사제외)
                  <span class='block xl:text-16 text-12 xl:mt-6 mt-16 text-subColor03'>※ 특허·실용신안·디자인 등록은 법인일 경우 법인명으로, 
                  개인사업체일 경우 대표자명으로 등록되어야만 됨
                  (공동등록 시 약정서 제출)</span>
                  `,
                  "현장시공에 의해 구매 목적물이 완성되는 반제품 등 품질확보가 곤란한 제품 및 의약픔(농약 포함)",
                  "조달물자로 공급하기 곤란한 음 · 식료품류, 동 · 식물류, 농 · 수산물류, 무기 · 총포 · 화약류와 그 구성품, 유류 등",
                  "2회 이상 우수조달공동상표 물품 지정에서 제외된 물품으로서 심사에 영향을 미칠 수 있는 서류가 추가로 제출되지 아니한 경우 ",
                  "신청서류가 위조, 변조 등 허위서류로 확인된 경우 ",
                  "신청자가 부도, 파산되었거나 기타 우수조달공동상표 물품지정이 곤란하다고 인정되는 경우",
                  "신청물품에 대한 생산공장이 없는 경우",
                  "부정당업자 제재기간 중인 경우",
                  "대표법인과 참여기업은 동일물품 또는 동일한 기술을 적용한 물품으로 ‘우수조달물품’과 중복하여 지정받을 수 없다. ",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "수의계약을 통해 우선구매가 가능함",
      },
      {
         desc: "우수조달물품 전시회 등 홍보를 통한 판로 지원",
      },
      {
         desc: "조달청 우수제품 등록 시 가점 부여 ",
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="field xl:pt-80 xl:pb-80 pt-60 pb-60 bg-subColor11">
            <div className="container">
               <h3 className="leading-1em xl:text-32 text-24 font-bold xl:mb-56 mb-24">신청 제품 및 분야</h3>
               <div className="main_img_wrap type_01">
                  <img className="main_img w-full" src={table04} alt="신청 제품 및 분야" />
               </div>
            </div>
         </section>
         <section className="procedure xl:pt-80 xl:pb-80 pt-60 pb-60">
            <div className="container">
               <h3 className="leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <p className="leading-1em xl:text-24 text-16 font-semibold xl:mb-40 mb-16">*지정계획 공고</p>
               <div className="main_img_wrap type_01">
                  <img className="main_img w-full" src={image01} alt="심사절차" />
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default ExcellenceCert;