import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Distinction, Overview } from "../../../components/SubPageCommonUI"

// images
import logo25 from "../../../images/certification/logo25.png"
import image01 from "../../../images/certification/type03-07.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon13 from "../../../images/common/icon/icon13.png"

// style
const Container = styled.div`
   & .table_wrap{
      & .table_img{
         width: 100%;
         border-bottom: 1px solid var(--subColor05);
      }
      & .list:not(:last-of-type){
         margin-bottom: 16px;
      }
   }
   & .examination{
      background-color: var(--subColor11);
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         height: auto;
      }
   }
   @media screen and (max-width:1280px){
      .examination{
         .main_img_wrap{
            .main_img{
               width: auto;
               height: 900px;
               max-height: calc(100vh - 60px);
            }
         }
      }
   }
`

const EpcCert = () => {

   const overViewInfo = {
      title: "성능인증(EPC)",
      desc: "성능인증은 공공기관의 기술개발 제품 구매 확대를 위해 중소기업이 기술개발한 제품의 성능을 검사하여 해당 제품의 성능확보를 확인·증명하는 제도입니다.",
      logo: logo25,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "중소벤처기업부",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "인증일로부터 4년",
            bgImg: icon09,
         },
         {
            title: "접수기간",
            desc: "정기심사 연 4회",
            bgImg: icon13,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "약 3개월 소요",
            bgImg: icon11,
         },
         {
            title: "심사비용",
            desc: "770,000원",
            bgImg: icon08,
         },
      ],
      sub_desc: "※ 업력 7년 이내 창업기업이면서, 소기업(또는 소상공인)인 경우에 한해 20% 할인",
   };
   const BenefitsData = [
      {
         desc: "성능인증, 성능보험 가입제품 구매로 발생하는 손실에 대해서는 구매자 면책",
      },
      {
         desc: "성능보험에 가입된 제품을 생산, 공급하는 기업에는 제한 입찰, 지명 경쟁 입찰 우선 참가자격 부여",
      },
      {
         desc: "조달청 우수제품 등록 시 가점 부여",
      }
   ]
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "국가연구개발사업 성공 판정 받은 과제의 기술 적용 제품",
                  "특허 및 실용신안을 사업화한 제품",
                  "우수재활용제품(GR)",
                  "소프트웨어 1등급 품질인증제품(GS)  *타제품과의 결합·융합 제품만 가능",
                  "조달청 우수조달제품",
                  "환경표지인증제품",
                  "신제품(NEP)",
                  "신기술(NET) 이용 제조 제품 *적용 제품 확인서가 발급 가능한 경우에 한함",
                  "녹색기술인증제품",
                  "공공기관-중소기업 공동 연구개발 성공제품(개발선정품 지정)",
                  "성과공유 기술개발과제(공공부문) 성공 제품",
                  "국방부 우수상용품 중 공공기관 납품 가능 제품",
                  "상생협력제품",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "※ 시행세칙 제6조제10항제5호부터 제6호, 제26조에 따른 품목",
               desc_text: [
                  "의약품(동ㆍ식물용 포함), 의약외품, 미생물, 농ㆍ수산물, 총포ㆍ화약류, 사행성 제품, 비 가공제품, 식ㆍ음료품(동ㆍ식물용 포함)",
                  "구성하는 핵심부품 일체가 수입품인 제품 및 독립적으로 성능을 발휘할 수 없는 부품 또는 반제품인 경우 ",
                  "법정 형식승인이 필요한 제품임에도 형식승인을 받지 아니한 제품",
                  "이미 발급된 성능인증서와 동일한 적용 기술 또는 동일한 규격이나 호칭을 사용한 제품",
               ]
            },
            {
               desc_title: "※ 신청이 반려되는 경우",
               desc_text: [
                  "신청 서류를 위조･변조하거나 허위 서류를 제출한 경우",
                  "성능인증 심사를 위해 규정한 자료를 제출하지 않거나 제출을 거부한 경우",
                  "성능인증 신청자가 부도, 휴폐업 등으로 성능인증 제품 지정이 곤란하다고 인정되는 경우",
                  "판로지원법 제8조의2제1항에 해당하는 기업이 생산한 제품인 경우",
                  "지정받고자 하는 제품이 위 신청 불가 품목에 해당하는 경우",
               ]
            },
         ]
      },
   }

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <Distinction />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="examination xl:pt-80 xl:pb-80 pt-60 pb-60">
            <div className="container">
               <h3 className="title leading-1em xl:text-32 text-24 font-bold xl:mb-0 mb-24">심사절차</h3>
               <div className="main_img_wrap type_01">
                  <img className="main_img w-full xl:mt-48" src={image01} alt="심사절차" />
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default EpcCert;