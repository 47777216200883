import { createBrowserRouter } from "react-router-dom"

// components
import NotFound from "./components/NotFound";
import DetailPage from "./components/DetailPage";

// pages 
import Home from "./pages/Home";
import Root from "./Root";
import About from "./pages/company/About";

// 회사소개
import Company from "./pages/company/layout";
import CertifiedCompany from "./pages/company/CertifiedCompany";
import Youtube from "./pages/company/Youtube";
import Consultant from "./pages/company/Consultant"
import CompanyContact from "./pages/company/Contact"

// 인증 분야
import ProcurementCommon from "./pages/certification/procurement/layout";
import QualityCertCommon from "./pages/certification/quality-cert/layout";
import TechCertCommon from "./pages/certification/tech-cert/layout";
import ManagementCert from "./pages/certification/management-cert/layout";

// 원스톱
import OneStopCommon from "./pages/one-stop/layout";
import ServiceInfo from "./pages/one-stop/ServiceInfo";
import ServiceStaff from "./pages/one-stop/ServiceStaff";
import Details from "./pages/one-stop/Details";

// 고객지원
import CustomerCommon from "./pages/customer/layout";
import Notice from "./pages/customer/Notice";
import Information from "./pages/customer/Information";
import Careers from "./pages/customer/Careers";
import Faq from "./pages/customer/Faq";
import Contact from "./pages/customer/Contact";

const router = createBrowserRouter([
   {
      path: '/',
      element: <Root />,
      children: [
         {
            path: '',
            element: <Home />
         },
         {
            path: '/company',
            element: <Company />,
            children: [
               {
                  path: '',
                  element: <NotFound />
               },
               {
                  path: '/company/about',
                  element: <About />
               },
               {
                  path: '/company/certified-company',
                  element: <CertifiedCompany />
               },
               {
                  path: '/company/youtube',
                  element: <Youtube />
               },
               {
                  path: '/company/consultant',
                  element: <Consultant />
               },
               {
                  path: '/company/contact',
                  element: <CompanyContact />
               },
            ]
         },
         {
            path: '/company/certified-company/:id',
            element: <DetailPage />
         },
         {
            path: '/company/youtube/:id',
            element: <DetailPage />
         },
         {
            path: '/certification/procurement',
            element: <ProcurementCommon />,
         },
         {
            path: '/certification/quality-cert',
            element: <QualityCertCommon />
         },
         {
            path: '/certification/tech-cert',
            element: <TechCertCommon />
         },
         {
            path: '/certification/management-cert',
            element: <ManagementCert />
         },
         {
            path: '/one-stop',
            element: <OneStopCommon />,
            children: [
               {
                  path: '',
                  element: <NotFound />
               },
               {
                  path: '/one-stop/service-info',
                  element: <ServiceInfo />
               },
               {
                  path: '/one-stop/details',
                  element: <Details />
               },
               {
                  path: '/one-stop/service-staff',
                  element: <ServiceStaff />
               },
            ]
         },
         {
            path: '/customer',
            element: <CustomerCommon />,
            children: [
               {
                  path: '',
                  element: <NotFound />
               },
               {
                  path: '/customer/notice',
                  element: <Notice />,
               },
               {
                  path: '/customer/information',
                  element: <Information />
               },
               {
                  path: '/customer/careers',
                  element: <Careers />
               },
               {
                  path: '/customer/faq',
                  element: <Faq />
               },
               {
                  path: '/customer/contact',
                  element: <Contact />
               }
            ],
         },
         {
            path: '/customer/notice/:id',
            element: <DetailPage />
         },
         {
            path: '/customer/information/:id',
            element: <DetailPage />
         },
      ],
      errorElement: <NotFound />
   },
])

export default router;