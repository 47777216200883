import styled from 'styled-components'

// components
import { Benefits02, CertificationInfo, CheckList, Consulting, ContactBanner, Overview } from '../../../components/SubPageCommonUI'

// images
import logo from "../../../images/certification/logo24.png"
import image01 from "../../../images/certification/type01-20.png"
import image02 from "../../../images/certification/type02-06.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import { MoItems01 } from '../../../components/StyledComponents'

// styles
const Container = styled.div`
   & .main_img{
      max-width: 1020px;
   }
`

const CompanyLab = () => {

   const overViewInfo = {
      title: "기업부설연구소",
      desc: "연구소/전담부서 설립신고 제도는 일정 요건을 갖춘 기업의 연구개발전담조직을 신고, 인정함으로써 기업내 독립된 연구조직을 육성하고 인정받은 연구소/전담부서에 대해서는 연구개발활동에 따른 지원혜택을 부여하여 기업의 연구개발을 촉진하는 제도입니다.",
      logo: logo,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "(사)한국산업기술진흥협회",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "없음",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "신청일로부터 약 7일 소요",
            bgImg: icon11,
         },
         {
            title: "심사비용",
            desc: "없음",
            bgImg: icon08,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "※ 연구소",
               desc_text: [
                  "연구전담요원 2명 이상 (벤처기업/연구원창업중소기업)",
                  "연구전담요원 3명이상 (소기업)",
                  "연구전담요원 5명 이상 (중기업)",
                  "연구전담요원 5명 이상 (국외에 있는 기업연구소(해외연구소))",
                  "연구전담요원 7명 이상 (중견기업)",
                  "연구전담요원 10명 이상 (대기업)",
               ],
            },
            {
               desc_title: "※ 연구개발전담부서",
               desc_text: [
                  "연구전담요원 1명 이상 (기업규모에 관계없이 동동적용)",
               ],
            },
            {
               desc_title: "※ 공통사항",
               desc_text: [
                  "연구개발활동을 수행해 나가는데 있어서 필수적인 독립된 연구공간과 연구시설을 보유하고 있을 것",
                  "자연계 분야(자연과학·공학·이학·농학·의학계열 등) 학사 이상 학위를 가진 사람",
                  "국가자격법에 의한 기술·기능분야 기사 이상 기술자격인 사람",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "영리를 목적으로 하지 않는 비영리 기관",
                  "「근로자직업능력」에 의한 기능대학, 직업훈련기관",
                  "「의료법」에 의한 의료법인",
                  "「사회복지사업법」에 의한 복지법인",
                  "「향교재산법」에 의한 종교법인 등",
                  "4대보험 미가입자",
                  "일반대학원에서 주간으로 석사 이상의 학위 취득과정에 수학하는 자",
                  "6개월 이상 연구개발활동을 수행할 수 없는 자 ",
                  "산업기능요원, 산업연수생(외국인)으로 복무 중인 자",
                  "상시 출근하지 않는 자문직이나 계약기간이 6개월 이하인 단기근로자",
                  "기업의 연구업무 이외에 다른 업무를 겸직하는 자 ",
               ]
            },
         ]
      },
   }
   const Benefits02Data = [
      {
         title: "※ 조세지원",
         items: [
            "일반연구·인력개발비 세액공제(공통)",
            "신성장동력산업 및 원천기술 연구·인력개발비 세액공제 (공통)",
            "연구 및 인력개발 설비투자 세액 공제(공통)",
            "기업부설연구소용 부동산 지방세 감면(기업부설연구소)",
            "연구전담요원 연구활동비 소득세 비과세(공통)",
         ],
      },
      {
         title: "※ 관세지원",
         items: [
            "산업기술 연구개발 물품 관세감면(공통)",
         ],
      },
      {
         title: "※ 인력지원",
         items: [
            "전문연구요원제도(기업부설연구소)",
            "고경력 연구인력 채용 사업(공통)",
         ],
      },
      {
         title: "※ 자금지원",
         items: [
            "국가연구개발사업 참여지원제도(공통)",
            "우수기술연구센터 사업(ATC)(기업부설연구소)",
         ],
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList info={CheckListData} />
         <section className='xl:pt-80 xl:pb-80 bg-subColor11'>
            <div className='container xl:flex justify-between'>
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div className='xl:block hidden'>
                  <img className='main_img xl:mt-24' src={image01} alt="심사 절차" />
               </div>
               <ul className="xl:hidden mo_items_wrap">
                  <MoItems01 bgColor="#75c5f5">연구소 (전담부서) 설립</MoItems01>
                  <MoItems01 bgColor="#48afec">설립신고 등록</MoItems01>
                  <MoItems01 bgColor="#2ea4e9">설립신고 접수 및 검토</MoItems01>
                  <MoItems01 bgColor="#1592dc">인증서 발급</MoItems01>
                  <MoItems01 bgColor="#1592dc">사후관리</MoItems01>
               </ul>
            </div>
         </section>
         <Benefits02 info={Benefits02Data} />
         <Consulting imgSrc={image02} />
         <ContactBanner />
      </Container>
   )
}

export default CompanyLab;