import styled from "styled-components"

// Props
import { MoItems01Props } from "../model/company/Procedure"

const MoItems01 = styled.li<MoItems01Props>`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 40px;
   font-size: 14px;
   color: #fff;
   background-color: ${(props) => props.bgColor};
   &:not(:last-of-type){
      margin-bottom: 8px;
   }
`

export { MoItems01 }