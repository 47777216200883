import styled from 'styled-components'

// components
import { Benefits02, CertificationInfo, CheckList, Consulting, ContactBanner, Overview } from '../../../components/SubPageCommonUI'

// images
import logo from "../../../images/certification/logo18.png"
import image01 from "../../../images/certification/type01-19.png"
import image02 from "../../../images/certification/type02-05.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import { MoItems01 } from '../../../components/StyledComponents'

// styles
const Container = styled.div`
   & .main_img{
      max-width: 1020px;
   }
`

const Innobiz = () => {

  const overViewInfo = {
    title: "이노비즈",
    desc: "Innovation(혁신)과 Business(기업)의 합성어로 기술 우위를 바탕으로 경쟁력을 확보한 기술혁신형 중소기업을 지칭합니다. 전 세계적으로 기술 혁신을 통해 기업과 국가의 경쟁력을 높이려는 뉴 패러다임이 새로운 화두로 떠오르고 있기에 미국, 독일 OECD 선진국들은 중소벤처기업을 국가경쟁력의 핵심으로 일찍이 95년부터 정부차원에서 전폭적인 지원 정책을 실시해 왔으며, 국가간의 경쟁력을 측정하는 객관적인 척도로 비교되고 있습니다.",
    logo: logo,
  }
  const CertificationData = {
    contents: [
      {
        title: "인증기관",
        desc: "중소벤처기업부",
        bgImg: icon07,
      },
      {
        title: "유효기간",
        desc: "3년",
        bgImg: icon09,
      },
      {
        title: "최대 신청 가능 횟수",
        desc: "없음",
        bgImg: icon10,
      },
      {
        title: "처리기간",
        desc: "신청일로부터 약 3~5주 소요",
        bgImg: icon11,
      },
      {
        title: "심사비용",
        desc: "신규 770,000원 <br/>기간연장 440,000원",
        bgImg: icon08,
      },
    ],
  };
  const CheckListData = {
    positive: {
      title: "신청 가능 대상",
      desc: [
        {
          desc_title: "",
          desc_text: [
            "중소기업 중 업력이 3년 이상인 기업",
            "제조업, 건설업, 농업",
            "비제조업(제조, 건설, 농업 제외 기타 업종)",
            "소프트웨어업",
            "바이오업(바이오산업 적용가능 업종 및 기술분야)",
            "환경업(환경산업 적용가능 업종 및 기술 분야)",
            "전문디자인업(인테리어/제품/시각지다인 등)",
          ],
        },
      ],
    },
    negative: {
      title: "신청 불가 대상",
      icon: "xi-close-circle",
      desc: [
        {
          desc_title: "",
          desc_text: [
            "연체, 국세체납 등으로 인하여 신용관리 정보 대상자로 규제를 받고 있는 기업",
            "어음교환소로부터 거래정지처분을 받은 기업",
            "파산, 회생절차개시, 개인회생절차개시 신청이 있거나 청산에 들어간 기업",
            "제외업종 : 숙박업, 음식점업, 부동산 및 임대업, 오락업, 미용업, 목욕마사지업, 세탁업, 장의업 등",
          ]
        },
      ]
    },
  }
  const Benefits02Data = [
    {
      title: "※ 금융/세제",
      items: [
        "수도권 취득세 중과 면제(각 지자체 세무과)",
        "정기 세무조사 유예 및 납부기한 연장 등 납부유예(국세청)",
        "부가가치세 환급금 조기지급 및 세금포인트 적립 점수 우대(국세청)",
        "기술혁신형 중소기업 M&A(중소벤처기업부/국세청)",
        "금융지원 협약보증 및 기술보증 우대지원(기술보증기금)",
        "기술보증 보증료 감면(기술보증기금)",
      ],
    },
    {
      title: "※ R&D",
      items: [
        "중소기업기술혁신 개발사업(중소벤처기업부) ",
        "창업성장기술개발 사업 디딤돌 과제(중소벤처기업부)",
        "혁신창업사업화자금-융자(중소벤처기업부)",
        "기술보호 정책보험 및 기술유출방지시스템 구축(중소벤처기업부)",
        "중소기업 기술거래 활성화 지원사업(중소벤처기업부/기술보증기금)",
        "성능인증(EPC)(중소벤처기업부 중소기업유통센터 성능인증팀)",
      ],
    },
    {
      title: "※ 인력",
      items: [
        "전문연구요원제도 -연구·학문분야(과학기술정보통신부)",
        "산업기능요원제도 -제조·생산분야(중소벤처기업부/중소벤처기업)",
        "중소기업 연구인력 지원사업(중소벤처기업부/국가과학기술연구회) ",
      ],
    },
    {
      title: "※ 판로 / 수출",
      items: [
        "물품구매 및 일반용역 적격심사(조달청)",
        "혁신형 중소기업 방송광고 지원(한국방송광고진흥공사)",
        "온라인수출패키지 지원 사업(중소벤처기업부/중소벤처기업진흥공단)",
        "글로벌 강소기업 1000+(중소벤처기업부/중소벤처기업진흥공단)",
        "수출자원기반활용 및 글로벌 비지니스 센터(중소벤처기업부)",
      ],
    },
    {
      title: "※ 기타",
      items: [
        "특허 출원 우선심사(특허청)",
      ],
    },
  ]

  return (
    <Container>
      <Overview
        info={overViewInfo}
      />
      <CertificationInfo
        info={CertificationData}
      />
      <CheckList info={CheckListData} />
      <section className='xl:pt-80 xl:pb-80 bg-subColor11'>
        <div className='container xl:flex justify-between'>
          <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
          <div>
            <p className='leading-1em xl:text-22 font-semibold'>※ 업력 3년 이상 중소기업</p>
            <img className='xl:block hidden main_img xl:mt-24' src={image01} alt="심사 절차" />
            <ul className="xl:hidden mo_items_wrap mt-16">
              <MoItems01 bgColor="#75c5f5">업종별 자가진단체크</MoItems01>
              <MoItems01 bgColor="#48afec">기술 능력 평가</MoItems01>
              <div>
                <MoItems01 bgColor="#2ea4e9">온라인 자가진단</MoItems01>
                <p className='leading-18 text-12 text-subColor03'>ㆍ650점 이상 통과</p>
              </div>
              <div>
                <MoItems01 bgColor="#1592dc">기술보증기금 현장평가</MoItems01>
                <p className='leading-18 text-12 text-subColor03'>ㆍ700점 이상 통과</p>
              </div>
              <div>
                <MoItems01 bgColor="#1592dc">등급별 업체선정</MoItems01>
                <p className='leading-18 text-12 text-subColor03'>ㆍ900점 이상:AAA</p>
                <p className='leading-18 text-12 text-subColor03'>ㆍ900~800점:AA</p>
                <p className='leading-18 text-12 text-subColor03'>ㆍ800~700점:A</p>
              </div>
              <MoItems01 bgColor="#1592dc">이노비즈기업 확인서 발급</MoItems01>
            </ul>
          </div>
        </div>
      </section>
      <Benefits02 info={Benefits02Data} />
      <Consulting imgSrc={image02} />
      <ContactBanner />
    </Container>
  )
}

export default Innobiz;