import styled from 'styled-components'

// components
import { Benefits, CheckList, ContactBanner, Distinction, Overview } from '../../../components/SubPageCommonUI'

// images
import logo from "../../../images/certification/logo15.png"
import image01 from "../../../images/certification/type04-01.png"
import image01Mo from "../../../images/certification/mobile_type04-01.png"
import image02 from "../../../images/certification/table09.png"

// styles
const Container = styled.div`
   & .main_img{
      max-width: 1020px;
   }
   .info{
      .main_img{
         max-width: 1280px;
      }
   }
   @media screen and (max-width:1280px){
      .info{
         .main_img_wrap{
            .main_img{
               width: auto;
               height: 900px;
               max-height: calc(100vh - 60px);
            }
         }
      }
   }
`

const NetCert = () => {

   const overViewInfo = {
      title: "신기술인증(NET)",
      desc: "개발된 신기술의 상용화와 기술거래 촉진과 신기술 적용제품의 신뢰성을 제고시켜 구매력을 창출하고 초기시장 진출기반 조성을 위해 <br class='xl:block hidden'/>국내기업 및 연구기관, 대학 등에서 개발한 신기술을 초기에 발굴하여 그 우수성을 인증하는 제도입니다.",
      logo: logo,
   }
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  '이론으로 정립된 기술을 시작품 등으로 제작하여 시험 또는 운영(이하 "실증화 시험")함으로써 정량적 평가지표를 확보한 개발완료기술로서 신청일을 기준으로 향후 2년이내에 상용화가 가능한 기술',
                  "실증화 시험을 통하여 정량적 평가지표를 확보한 개발완료기술로서 향후 기존 제품 성능을 현저히 개선시킬 수 있는 기술",
                  "제품의 생산성이나 품질을 향후 현저히 향상시킬 수 있는 공정기술",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "신청기술이 적용된 제품이 상용화(시장에 제품을 출하 및 판매)되었을 경우",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      { desc: "신기술 적용제품의 수의계약 지원" },
      { desc: "기술개발제품 우선 및 시범 구매제도" },
      { desc: "우수조달제품 지정 지원" },
      { desc: "공공조달 상생협력 지원제도" },
      { desc: "산업기술진흥 유공(신기술실용화) 정부포상" },
      { desc: "정부 및 지자체 R&D, 수출지원, 홍보지원 사업 신청시 우대" },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <Distinction />
         <section className='xl:pt-80 xl:pb-80 pt-60 pb-60 bg-subColor11'>
            <div className='container xl:flex justify-between'>
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">인증 종류</h3>
               <img className='xl:block hidden main_img w-full' src={image01} alt="인증 종류" />
               <div className='xl:hidden'>
                  <p className='text-center text-14 leading-20 mb-24 mt-24 text-subColor03'>
                     23분 (발표 10분,<br />
                     기술설명 3분, 질의 응답 10분)
                  </p>
                  <img className='main_img w-full' src={image01Mo} alt="인증 종류" />
               </div>
            </div>
         </section>
         <CheckList
            info={CheckListData}
         />
         <section className='info xl:pt-80 xl:pb-80 bg-subColor11'>
            <div className='container'>
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">인증별 정보</h3>
               <div className='main_img_wrap type_01'>
                  <img className='main_img w-full xl:mt-48' src={image02} alt="인증별 정보" />
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container>
   )
}

export default NetCert