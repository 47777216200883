import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import image01 from "../../../images/certification/type03-04.png"
import image02 from "../../../images/certification/type03-05.png"
import image03 from "../../../images/certification/type03-06.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon13 from "../../../images/common/icon/icon13.png"

// style
const Container = styled.div`
   & .target_wrap{
      & .title{
         white-space: nowrap;
      }
      & .item_wrap{
         width: 100%;
      }
   }
   & .examination{
      background-color: var(--subColor11);
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         width: 100%;
         height: auto;
      }
   }
   & .process{
      & .main_img{
      max-width: 1020px;
      }
   }
   @media screen and (max-width:1280px){
      .process{
         .main_img_wrap{
            &:not(:first-of-type){
               .main_img{
                  margin-top: 40px;;
               }
            }
            .main_img{
               width: auto;
               &.type_01{
                  width: auto;
                  height: 310px;
               }
               &.type_02{
                  width: auto;
                  height: 240px;
               }
               &.type_03{
                  width: auto;
                  height: 390px;
               }
            }
         }
      }
   }
`

const Software3party = () => {

   const overViewInfo = {
      title: "소프트웨어 3자단가계약",
      desc: "일반적이거나 특정한 목적을 위하여 개발되어 하나의 상품으로 출시되고 판매를 목적으로 완성된 상용 소프트웨어를 대상으로 하며, <br class='xl:block hidden'/>조달청 나라장터 쇼핑몰을 통해서 수요기관이 공통으로 필요로 하는 물자를 제조, 구매 및 가공하는 등의 계약을 할 때 미리 단가를 정하고 각 수요기관이 직접 납품요구나 대금지급을 할 수 있는 계약입니다.",
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "조달청",
            bgImg: icon07,
         },
         {
            title: "심사비용",
            desc: "없음",
            bgImg: icon08,
         },
         {
            title: "유효기간",
            desc: "3년",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "접수 마감일로부터 약 3개월 소요",
            bgImg: icon11,
         },
         {
            title: "접수기간",
            desc: "상시 접수",
            bgImg: icon13,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "※ 제 3자단가 계약은 상용 소프트웨어를 대상으로 함",
               desc_text: [
                  "상용화된 소프트웨어로 특정한 목적을 위하여 개발되어 하나의 상품으로 출시되고 판매를 목적으로 완성된 소프트웨어",
                  "규격화되어 단가책정이 가능한 소프트웨어(수요기관 설치형)",
                  "GS인증 또는 국가정보원장이 정한 정보보호시스템 유형별 도입요건을 준수한 제품(해당제품의 경우 CC인증 필수)의 인증서",
                  "최근 1년 이내에 3건 이상의 민간 또는 공공부문의 판매자료를 말하며, 1년 이내 거래내역이 3건 미만인 경우에는 최대 2년 이내의 판매자료 제출",
               ],
            },
         ],
      },
   }
   const BenefitsData = [
      {
         desc: "상용SW 쇼핑몰제품 우선 구매",
      },
      {
         desc: "계약을 체결함에 있어 금액 및 성격 등이 기준에 해당하는 경우 조달청장에게 계약 체결",
      },
      {
         desc: "대상소프트웨어가 국가종합전자 조달시스템의 종합쇼핑몰에 등록된 제품일경우 조달청장에게 계약 체결",
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="process xl:pt-80 xl:pb-80 pt-60 pb-60 bg-subColor11">
            <div className="container">
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-0 mb-24">심사절차</h3>
               <div className="main_img_wrap type_01">
                  <img className="main_img type_01 w-full xl:mt-48" src={image01} alt="심사절차" />
               </div>
               <div className="main_img_wrap type_01">
                  <img className="main_img type_02 w-full xl:mt-48" src={image02} alt="심사절차" />
               </div>
               <div className="main_img_wrap type_01">
                  <img className="main_img type_03 w-full xl:mt-48" src={image03} alt="심사절차" />
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default Software3party;