import styled from "styled-components"

// Images
import image01 from "../../images/service-info/details01.png"
import image02 from "../../images/service-info/details02.png"
import image03 from "../../images/service-info/details03.png"
import image04 from "../../images/service-info/details04.png"

// styles
const Container = styled.div`
   .section02 .content {
      display: flex;
      gap: 48px;
      border: 1px solid var(--subColor05);
   }

   .section02 .content .main_img {
      width: 50%;
   }

   .section02 .content.type02 {
      gap: 40px;
   }

   .section02 .content.type02 .main_img {
      order: 1;
   }

   .section02 .content.type02 .text_wrap {
      order: 0;
      padding-left: 56px;
   }

   .section02 .content.type02 .text_wrap .num {
      padding-right: 0px;
   }

   .section02 .content .text_wrap {
      width: 50%;
      padding: 24px 0px;
   }

   .section02 .content .text_wrap .num {
      opacity: 0.2;
      width: 100%;
      text-align: right;
      font-size: 90px;
      padding-right: 40px;
      -webkit-text-stroke: 2px var(--pointColor02);
      color: transparent;
   }

   .section02 .content .text_wrap .tit {
      position: relative;
   }

   .section02 .content .text_wrap .tit::before {
      content: '';
      display: block;
      clear: both;
      width: 30px;
      height: 3px;
      margin-bottom: 16px;
      background-color: var(--pointColor01);
   }

   .section02 .content .text_wrap .item_wrap .item {
      display: flex;
      align-items: flex-start;
      gap: 8px;
   }

   .section02 .content .text_wrap .item_wrap .item .icon {
      color: var(--pointColor01);
   }
   @media screen and (max-width:1024px){
      .section02 {
         .content, .content.type02{
            display: block;
            .main_img{
               width: 100%;
            }
            .text_wrap{
               width: 100%;
               padding: 32px 16px;
               .num{
                  font-size: 60px;
                  padding-right: 0px;
                  margin-bottom: 0px;
               }
            }
         }
      }
   }
`

const Details = () => {
   return (
      <Container>
         <div>
            <h3 className="text-center xl:text-40 text-24 font-bold xl:mt-80 mt-60">세부내용</h3>
            <section className="section02 container xl:mt-80 mt-24">
               <div className="content01 content">
                  <img className="main_img" src={image01} alt="" />
                  <div className="text_wrap">
                     <strong className="num leading-1em xl:mb-24 mb-16">01</strong>
                     <h4 className="tit xl:leading-32 leading-28 xl:text-22 text-20 font-bold xl:mb-24 mb-16">특허 무료 분석</h4>
                     <ul className="item_wrap">
                        <li className="item xl:mb-16 mb-8">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="xl:leading-28 leading-22 xl:text-16 text-14">목적: 의뢰 기업 제품에 대한 통상적 특허분석</p>
                        </li>
                        <li className="item xl:mb-16 mb-8">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="xl:leading-28 leading-22 xl:text-16 text-14">제공서비스: 인증 가능성 분석&#40;일반 변리사가 쓰는 특허분석 프로그램으로 특허 분석&#41;</p>
                        </li>
                        <li className="item xl:mb-16 mb-8">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="xl:leading-28 leading-22 xl:text-16 text-14">비용 및 기간: 무료, 2일</p>
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="content02 content type02 xl:mt-80 mt-42 xl:mb-80 mb-48">
                  <img className="main_img" src={image02} alt="" />
                  <div className="text_wrap">
                     <strong className="num leading-1em xl:mb-24 mb-16">02</strong>
                     <h4 className="tit xl:leading-32 leading-28 xl:text-22 text-20 font-bold xl:mb-24 mb-16">기술/특허 분석 및 진단</h4>
                     <ul className="item_wrap">
                        <li className="item xl:mb-16 mb-8">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="xl:leading-28 leading-22 xl:text-16 text-14">목적: 의뢰 기업 제품에 대한 통상적 특허분석</p>
                        </li>
                        <li className="item xl:mb-16 mb-8">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="flex xl:gap-4 xl:leading-28 leading-22 xl:text-16 text-14">
                              <span>제공서비스: </span>
                              <span>기업이 보유하고 있는 특허에 대한 심층 분석 및 진단, 경쟁사 우수조달제품 인증현황 및 제품 조사 및 분석</span>
                           </p>
                        </li>
                        <li className="item">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="xl:leading-28 leading-22 xl:text-16 text-14">비용 및 기간: 200만원/건, 1~2주</p>
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="content03 content">
                  <img className="main_img" src={image03} alt="" />
                  <div className="text_wrap">
                     <strong className="num leading-1em xl:mb-24 mb-16">03</strong>
                     <h4 className="tit xl:leading-32 leading-28 xl:text-22 text-20 font-bold xl:mb-24 mb-16">우수조달제품 인증 준비를 위한 특허전략 컨설팅</h4>
                     <ul className="item_wrap">
                        <li className="item xl:mb-16 mb-8">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="flex xl:gap-4 xl:leading-28 leading-22 xl:text-16 text-14">
                              <span>목적: </span>
                              <span>
                                 인증 획득을 위한 의뢰기업의 기술수준에 맞는 기술/특허 확보,
                                 제품에 적용 가능한 기술/특허 보완 및 신규출원 가이드
                              </span>
                           </p>
                        </li>
                        <li className="item xl:mb-16 mb-8">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="flex xl:gap-4 xl:leading-28 leading-22 xl:text-16 text-14">
                              <span>제공서비스: </span>
                              <span>
                                 특허동향조사 및 특허맵 작성
                                 기술추세 분석, 기술수준 분석, 경쟁사 분석
                                 혁신기술 문헌 목록&#40;Excel파일, 원문링크 등&#41;
                                 보유특허 보완 및 신규 특허 창출
                              </span>
                           </p>
                        </li>
                        <li className="item xl:mb-16 mb-8">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="xl:leading-28 leading-22 xl:text-16 text-14">비용 및 기간: 500만원, 4주</p>
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="content02 content type02 xl:mt-80 mt-42 xl:mb-80 mb-48">
                  <img className="main_img" src={image04} alt="" />
                  <div className="text_wrap">
                     <strong className="num leading-1em xl:mb-24 mb-16">04</strong>
                     <h4 className="tit xl:leading-32 leading-28 xl:text-22 text-20 font-bold xl:mb-24 mb-16">특허 가치평가</h4>
                     <ul className="item_wrap">
                        <li className="item xl:mb-16 mb-8">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="flex xl:gap-4 xl:leading-28 leading-22 xl:text-16 text-14">
                              <span>목적: </span>
                              <span>
                                 IP 경영 전략 구상 및 구체화
                                 기술이전&#40;기술양도, 기술 매매, 라이선스 등&#41;
                                 기술금융&#40;담보, 투자&#41;, 현물 출자
                              </span>
                           </p>
                        </li>
                        <li className="item xl:mb-16 mb-8">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="xl:leading-28 leading-22 xl:text-16 text-14">
                              <span>제공서비스: </span>
                              <span>
                                 기술/특허가 사업을 통하여 창출할 수 있는 경제적 가치를 정량적으로 평가
                                 보유 특허의 기술성, 시장성, 사업성, 권리성 평가
                              </span>
                           </p>
                        </li>
                        <li className="item">
                           <i className="icon xl:pt-2 xi-check-min xl:text-21"></i>
                           <p className="xl:leading-28 leading-22 xl:text-16 text-14">비용 및 기간: 300만원, 3~4주</p>
                        </li>
                     </ul>
                  </div>
               </div>
            </section>
         </div>
      </Container>
   )
}

export default Details;