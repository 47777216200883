import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

// types
import { CompanyNoticeProps } from '../../model/company/Notice'

// components
import PageControl from '../../components/PageControl'

// images 
import noImg from "../../images/common/noimg02.png"
import styled from 'styled-components'

// styles
const Container = styled.div`
   .tab_wrap {
      display: flex;
      width: fit-content;
      border: 1px solid var(--sub_color05);
      margin-left: auto;
      margin-right: auto;
   }
   .tab_wrap .tab_item .btn_tab {
      width: 207px;
      height: 70px;
   }
   .tab_wrap .tab_item:not(:last-of-type) .btn_tab {
      border-right: 1px solid var(--sub_color05);
   }
   .tab_wrap .tab_item .btn_tab.on {
      color: #fff;
      background-color: var(--main_color03);
   }

   .product_list_wrap .product_count .num {
      color: var(--main_color03);
   }
   .product_list_wrap .product_wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 48px 32px;
   }
   .product_list_wrap .product_wrap .product_item .product_tit {
      /* overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; */
      width: 100%;
   }
   .btn_link:hover>.icon_search_wrap.type01,
   .btn_link:focus>.icon_search_wrap.type01 {
      display: flex;
   }
   .btn_link.type-search-wrap {
      position: relative;
   }
   .icon_search_wrap.type01 {
      display: none;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
   }
   .product_list_wrap .product_wrap .thumbnail{
      object-fit: cover;
      width: 100%;
      height: auto;
   }
   .product_list_wrap .product_wrap .btn_link{
      width: 100%;
      overflow: hidden;
      border-radius: 16px
   }
   .product_list_wrap .product_wrap .category_wrap {
      display: flex;
      gap: 8px;
   }
   .product_list_wrap .product_wrap .category_wrap .category {
      border: 1px solid var(--pointColor01);
      border-radius: 9999px;
      color: var(--pointColor01);
   }
`

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

const Youtube = () => {

   // API
   const query = useQuery();
   const page = query.get('page');
   const search = query.get('search');

   const [data, setData] = useState<CompanyNoticeProps>();
   const [loading, setLoading] = useState(true);

   const [searchTerm, setSearchTerm] = useState(search ? search : '');
   const [searchTermWrap, setSearchTermWrap] = useState(search ? search : '');
   const [pageIndex, setPageIndex] = useState(page ? page : 1);

   const navigate = useNavigate();

   const handleChange = (event: any) => {
      setSearchTermWrap(event.target.value);
   };

   const handleSubmit = (event: any) => {
      event.preventDefault();
      const regex = /^[a-zA-Z0-9가-힣\s]*$/;

      if (!regex.test(searchTermWrap)) {
         alert("영문[대소문자], 한글[자음+모음], 숫자로 입력해주세요");
         return;
      }

      setPageIndex(1)
      setSearchTerm(searchTermWrap);
      navigate(`?page=${pageIndex}&search=${searchTermWrap}`);
   };


   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/youtube?page=${pageIndex}&search=${searchTerm}`);
            setData(response.data.data);
            if (response.data.success === false) {
               alert(response.data.message);
            }
         } catch (error) {
            console.log(error);
         } finally {
            setLoading(false)
         }
      };


      fetchData();
      navigate(`?page=${pageIndex}&search=${searchTerm}`);
   }, [searchTerm, pageIndex, navigate]);

   return (
      <Container>
         <div className="top_nav type_01 container xl:mt-80">
            <p className="text_count xl:text-18 xl:mt-0 mt-24">총 {data && <strong className="point">{data.total}</strong>}개의 게시물</p>
            <form className="search_from" onSubmit={handleSubmit}>
               <fieldset>
                  <legend className="hide">검색</legend>
                  <div className="search_box">
                     <input
                        id="search"
                        type="search"
                        name="search"
                        placeholder="검색어를 입력하세요"
                        value={searchTermWrap}
                        onChange={handleChange}
                     />
                     <button onClick={handleSubmit} type="button">
                        <i className="xi-search icon_search" />
                     </button>
                  </div>
               </fieldset>
            </form>
         </div>
         <div className="container xl:mb-48">
            <div className="product_list_wrap">
               <ul className="product_wrap grid xl:mt-24 mt-16">
                  {data ? (
                     data.data.map((data, index) => (

                        <li className="product_item" key={index}>
                           <Link to={`./${data.id}?page=${pageIndex}&search=${searchTerm}`} className="btn_link type-search-wrap xl:mb-24 mb-16">
                              <img
                                 className="thumbnail"
                                 src={data.video_id ? (`https://img.youtube.com/vi/${data.video_id}/maxresdefault.jpg`) : (noImg)}
                                 alt="썸네일"
                              />
                              <div className="icon_search_wrap type01">
                                 <i className="xi-search xl:text-44 text-white"></i>
                              </div>
                           </Link>
                           <h5 className="product_tit max_line2 xl:leading-28 leading-22 xl:text-20 text-16 font-bold xl:mt-10 mt-8">{data.title}</h5>
                           <p className="date leading-1em xl:text-16 text-14 xl:mt-16 mt-8 text-subColor04">{data.created_at_formatted}</p>
                        </li>
                     ))
                  ) : (
                     <li className="hide_txt">Loading ...</li>
                  )}
                  {data && data.total === 0 && <li>게시글이 없습니다.</li>}
               </ul>
            </div>
            {data &&
               <PageControl
                  data={data}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  loading={loading}
               />}
         </div>
      </Container>
   )
}

export default Youtube