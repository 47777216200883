import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo06 from "../../../images/certification/logo06.png"
import image01 from "../../../images/certification/type01-08.png"
import table05 from "../../../images/certification/table05.png"
import table06 from "../../../images/certification/table06.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon13 from "../../../images/common/icon/icon13.png"
import { MoItems01 } from "../../../components/StyledComponents"

// style
const Container = styled.div`
   & .examination{
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         max-width: 1020px;
         height: auto;
      }
   }
   @media screen and (max-width:1280px){
      .cost{
         .main_img_wrap{
            .main_img{
               width: auto;
               height: 240px;
            }
         }
      }
   }
`

const EcoLabel = () => {

   const overViewInfo = {
      title: "환경표지인증",
      desc: "동일 용도의 제품 중 제품의 전과정 각 단계에 걸쳐 에너지 및 자원의 소비를 줄이고 오염물질의 발생을 최소화할 수 있는 제품에 환경표지를 인증하는 국가 공인제도 기업과 소비자가 환경친화적인 제품을 생산 및 소비할 수 있도록 소비자에게 정확한 제품의 환경정보를 제공하여 환경보전활동에 참여토록 하고, 기업에게는 소비자의 친환경적 구매욕구에 부응하는 환경친화적인 제품과 기술을 개발하도록 유도하여 지속 가능한 생산과 소비생활을 이루는 것을 목적으로 합니다. ",
      logo: logo06,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "환경부(한국환경산업기술원)",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "인증 취득일로부터 3년",
            bgImg: icon09,
         },
         {
            title: "접수기간",
            desc: "상시 접수",
            bgImg: icon13,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "약 3~4개월 소요(제품에 따라 상이)",
            bgImg: icon11,
         },
         {
            title: "심사비용",
            desc: "제품당 5만원",
            bgImg: icon08,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "사무용 기기·가구 및 사무용품, 주택·건설용 자재·재료 및 설비, 개인용품 및 가정용품, 가정용 기기·가구, 교통·여가·문화 관련 제품, 산업용 제품·장비, 복합용도 및 기타, 서비스 등 「환경표지대상제품 및 인증기준」 고시 [별표 1] 및 [별표 2]에 따른 대상 제품",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "「식품위생법」에 의한 식품",
                  "「약사법」에 의한 의약품 및 의약외품",
                  "「농약관리법」에 의한 농약",
                  "「산림자원의 조성 및 관리에 관한 법률」에 의한 임산물로 지정된 목제품",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "정부포상 제도에 추천",
      },
      {
         desc: "인증제품 홍보 및 유통판매처 개척지원",
      },
      {
         desc: "공공기관의 의무구매",
      },
      {
         desc: "지자체 및 정부 운영제도에서 인증제품 사용 혜택",
      },
      {
         desc: "조달청 우수제품 등록 지원",
      },
      {
         desc: "뉴스레터 및 광고를 활용하여 인증제품 홍보",
      }
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="bg-subColor11 xl:pt-80 xl:pb-80 pt-60 pb-60">
            <div className="cost container">
               <h3 className="title leading-1em xl:text-32 text-24 font-bold xl:mb-0 mb-24">심사비용</h3>
               <div className="xl:mt-40">
                  <ul>
                     <li className="list-disc xl:leading-26 xl:text-18 text-14 xl:ml-16 xl:mb-16 ml-14">기본 수수료 : 제품당 5만원</li>
                     <li className="list-disc xl:leading-26 xl:text-18 text-14 xl:ml-16 xl:mb-16 ml-14">인증심사비/ 출장비 : 엔지니어링 사업대가 기준 및 공무원 여비 기준</li>
                     <li className="list-disc xl:leading-26 xl:text-18 text-14 xl:ml-16 xl:mb-16 ml-14">인증 사용료 : 인증 승인 제품의 전년도 연간 매출액 기준으로 산정 &#40;*사용료가 2백만원 이상인 경우, 2회 분할 납부 가능&#41;</li>
                     <div className="main_img_wrap type_01 xl:mt-0 mt-16">
                        <img className="main_img w-full xl:mt-32" src={table05} alt="심사비용" />
                     </div>
                     <p className="text-right leading-1em xl:text-16 text-12 xl:mt-24 text-subColor03">※부가가치세 별도</p>
                  </ul>
                  <div className="xl:mt-48 mt-40">
                     <p className="leading-1em xl:text-24 text-14 font-semibold">※ 인증사용료 경감 조건</p>
                     <div className="main_img_wrap type_01 xl:mt-0 mt-16">
                        <img className="main_img w-full xl:mt-24" src={table06} alt="인증사용료 경감 조건" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="examination xl:pt-80 xl:pb-80 pt-60 pb-60">
            <div className="container xl:flex justify-between">
            <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <img className="xl:block hidden main_img w-full" src={image01} alt="심사절차" />
               <ul className="xl:hidden mo_items_wrap">
                  <MoItems01 bgColor="#75c5f5">환경표지 인증신청서 제출</MoItems01>
                  <MoItems01 bgColor="#48afec">신청서 접수</MoItems01>
                  <MoItems01 bgColor="#2ea4e9">서류 검증</MoItems01>
                  <MoItems01 bgColor="#1592dc">현장심사 계획 통보</MoItems01>
                  <MoItems01 bgColor="#1592dc">현장 심사(제품 인증)</MoItems01>
                  <MoItems01 bgColor="#0e8dd7">현장 심사(서비스 인증)</MoItems01>
                  <MoItems01 bgColor="#0285d1">심의 자료 작성</MoItems01>
                  <MoItems01 bgColor="#067abe">시험성적서 수령</MoItems01>
                  <MoItems01 bgColor="#0072b5">심의 자료 작성</MoItems01>
                  <MoItems01 bgColor="#006aa8">인증 심의의원회</MoItems01>
                  <MoItems01 bgColor="#005c92">인증서 교부</MoItems01>
                  <MoItems01 bgColor="#004670">환경표지 사용</MoItems01>
               </ul>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default EcoLabel;