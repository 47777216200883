import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"
import { MoItems01 } from "../../../components/StyledComponents"

// images
import image01 from "../../../images/certification/type01-04.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"

// style
const Container = styled.div`
   & .target_wrap{
      & .title{
         white-space: nowrap;
      }
      & .item_wrap{
         width: 100%;
      }
   }
   & .examination{
      background-color: var(--subColor11);
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         width: 100%;
         height: auto;
      }
   }
   & .process{
    & .main_img{
      max-width: 1020px;
    }
   }
`

const DigitalService = () => {

  const overViewInfo = {
    title: "디지털서비스인증",
    desc: "정부는 전문계약 대상이 되는 디지털서비스의 정의를 명확하게 하기 위해, 「클라우드컴퓨팅 발전 및 이용자 보호에 관한 법률 시행령」 을 개정하여 디지털서비스 정의를 신설하였으며 디지털서비스는① 클라우드컴퓨팅서비스 ② 클라우드컴퓨팅서비스를 지원하는 서비스 ③ 지능정보기술 등 다른 기술 서비스와 클라우드컴퓨팅기술을 융합한 서비스로 구성됩니다.",
  }
  const CertificationData = {
    contents: [
      {
        title: "인증기관",
        desc: "한국기능정보사회진흥원 <br/>과학기술정보통신(심사위원회) <br/>조달청 ",
        bgImg: icon07,
      },
      {
        title: "심사비용",
        desc: "없음",
        bgImg: icon08,
      },
      {
        title: "유효기간",
        desc: "계약기간 3년",
        bgImg: icon09,
      },
      {
        title: "최대 신청 가능 횟수",
        desc: "없음",
        bgImg: icon10,
      },
      {
        title: "처리기간",
        desc: "접수 마감일로부터 약 3개월 소요",
        bgImg: icon11,
      },
    ],
  }
  const CheckListData = {
    positive: {
      title: "신청 가능 대상",
      desc: [
        {
          desc_title: "※ 클라우드컴퓨팅 서비스",
          desc_text: [
            "클라우드컴퓨팅 을 활용하여 상용으로 타인에게 정보통신자원을 제공하는 서비스집적 공유된 정보통신기기, 정보통신설비, 소프트웨어 등 정보통신자원을 이용자의 요구나 수요변화에 따라 정보통신망을 통하여 신축적으로 이용할 수 있도록 하는 정보 처리체계",
          ],
        },
        {
          desc_title: "※ 클라우드컴퓨팅 서비스를 지원하는 서비스(이하 클라우드 지원서비스)",
          desc_text: [
            "클라우드컴퓨팅서비스 도입 및 전환에 필요한 컨설팅,운영관리,마이그레이션 등을 조합하여 지원하는 서비스",
            "클라우드컴퓨팅서비스 도입 및 전환에 필요한 요구사항분석,현황분석,타당성검토, 도입전략수립 등을 지원하는 서비스",
            "클라우드 인프라 시스템을 안정적으로 운영하기 위한 기술지원,모니터링,장애처리, 백업 및 복구 등의 서비스",
            "기존 시스템 운영환경의 일부 또는 전부를 클라우드컴퓨팅서비스 운영환경으로 전환 하는 것을 지원하는 서비스",
            "기타 클라우드컴퓨팅서비스를 지원하는 서비스",
          ],
        },
        {
          desc_title: "※ 다른 기술/서비스와 클라우드컴퓨팅 기술을 융합한 서비스(이하 융합서비스)",
          desc_text: [
            "클라우드컴퓨팅기술 및 다른 기술/서비스가 융합된 서비스",
            "클라우드컴퓨팅의 구축 이용에 관한 정보통신기술로서, 가상화기술,분신처리기술, 그 밖에 정보통신자원의 배치와 관리 등을 자동화 하는 기술 등",
          ],
        },
      ],
    },
  }
  const BenefitsData = [
    {
      desc: "시행령 제26조제1항제5호 항목에 따른 수의계약",
    },
    {
      desc: "조달사업에 관한 법률 시행령 제16조에 따른 카탈로그 계약",
    },
    {
      desc: "수 개의 전문분야가 요구되는 복합 사업에 해당한다고 판단되는 계약에 대하여 공동계약 허용",
    },
    {
      desc: "수년간 존속할 필요가 있는 디지털서비스 계약에 대하여 장기 계약 체결 가능",
    },
  ]

  return (
    <Container>
      <Overview
        info={overViewInfo}
      />
      <CertificationInfo
        info={CertificationData}
      />
      <CheckList
        info={CheckListData}
      />
      <section className="process xl:pt-80 xl:pb-80 bg-subColor11">
        <div className="container xl:flex justify-between">
          <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
          <img className="xl:block hidden main_img w-full" src={image01} alt="심사절차" />
          <ul className="xl:hidden mo_items_wrap">
            <MoItems01 bgColor="#75c5f5">디지털서비스 심사신청</MoItems01>
            <MoItems01 bgColor="#48afec">심사/선정&#40;심사위원회&#41;</MoItems01>
            <MoItems01 bgColor="#2ea4e9">이용지원시스템 등록/공개</MoItems01>
            <MoItems01 bgColor="#1592dc">디지털서비스 전용쇼핑몰 계약</MoItems01>
            <MoItems01 bgColor="#1592dc">디지털서비스 사용</MoItems01>
          </ul>
        </div>
      </section>
      <Benefits
        info={BenefitsData}
      />
      <ContactBanner />
    </Container >
  )
}

export default DigitalService;