import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components'
import { SubTopArea } from '../../model/subPageTopArea';

// styles
const Container = styled.div`
   .btn_category{
      height: 1em;
      border-right: 1px solid var(--subColor04);
      &:last-of-type{
         border: 0px none;
      }
   }
`
const NavItemWrap = styled.ul`
   display: flex;
   justify-content: center;
   gap: 32px;
   @media screen and (max-width:1280px){
      position: relative;
      padding-top: 80px;
   }
`

const CustomerCommon = () => {

   const [changeKey, setChangeKey] = useState(0);
   const pathname = useLocation().pathname;
   const [categoryWrapIndex, setCategoryWrapIndex] = useState(0);

   const handleChangeKey = () => {
      setChangeKey(prev => prev + 1);
   }

   const pageTitles: { [key: string]: string } = {
      "/company/about": "우수조달컨설팅소개",
      "/company/certified-company": "인증성공업체",
      "/company/youtube": "유튜브소개",
      "/company/consultant": "컨설턴트 소개",
      "/company/contact": "찾아오시는 길"
   };

   const [currentName, setCurrentName] = useState<string>(pageTitles[pathname]);

   useEffect(() => {
      setCurrentName(pageTitles[pathname] || "");
   }, [pathname]);

   const info: SubTopArea = {
      title: "고객지원",
      category: [
         { name: "공지사항", link: "/customer/notice" },
         { name: "정보공유", link: "/customer/information" },
         { name: "채용정보", link: "/customer/careers" },
         { name: "자주묻는 질문", link: "/customer/faq" },
         { name: "문의하기", link: "/customer/contact" },
      ],
      bannerText: "최고 실적과 함께하는 <span class='point'>인증의 명가,</span> <br/>당신의 성공 파트너",
      currentInfo: {
         name: currentName,
         bgImg: "",
      },
   }

   const handleMobileCategory = () => {
      setCategoryWrapIndex((prev) => prev === 0 ? 1 : 0)
   }

   return (
      <Container className='xl:pt-200 pt-130'>
         <div>
            <h2 className="text-center xl:text-48 text-24 font-bold xl:mb-48">고객지원</h2>
            <NavItemWrap className="xl:mb-80">
               <div className={`${categoryWrapIndex === 1 ? "on" : ""} xl:hidden only-mb btn_category_wrap flex justify-center`}>
                  {info.category.map((category, index) => (
                     <Link
                        to={category.link}
                        className={`${category.link === pathname ? "on" : ""} btn_category leading-1em xl:text-20 text-14 xl:pr-24 xl:mr-24 text-subColor04`}
                        key={index}
                        onClick={() => handleMobileCategory()}
                     >
                        {category.name}
                        <i className='icon_arrow xl:hidden inline-block xi-angle-down-min'></i>
                     </Link>
                  ))}
               </div>
               <div className="btn_category_wrap xl:flex hidden justify-center">
                  {
                     info.category.map((category, index) => (
                        <Link
                           to={category.link}
                           className={`${pathname === category.link ? "on" : ""} btn_category leading-1em xl:text-20 text-14 xl:pr-24 xl:mr-24 text-subColor04`}
                           key={index}
                           onClick={() => handleChangeKey()}
                        >
                           {category.name}
                        </Link>
                     ))
                  }
               </div>
            </NavItemWrap>
         </div>
         <Outlet key={changeKey} />
      </Container>
   )
}

export default CustomerCommon