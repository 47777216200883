import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo09 from "../../../images/certification/logo09.png"
import image01 from "../../../images/certification/type01-11.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon13 from "../../../images/common/icon/icon13.png"
import { MoItems01 } from "../../../components/StyledComponents"

// style
const Container = styled.div`
   & .examination{
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         max-width: 1020px;
         height: auto;
      }
   }
`

const GsCert = () => {

   const overViewInfo = {
      title: "GS인증",
      desc: "소프트웨어 제품의 품질확보 및 유통촉진을 위하여 「소프트웨어 진흥법」 제20조에 의거하여 과학기술정보통신부가 고시한 소프트웨어 품질인증 기준에 만족하는 경우 소프트웨어 품질 인증서 및 품질인증 마크를 부여하는 제도로, 소프트웨어 품질인증 기준 적용시 국제표준인 ISO/IEC 25023 SW 제품 품질 측정에 관한 표준, ISO/IEC 25051 SW 제품 품질 요구사항과 시험에 관한 국제 표준을 참고한다.",
      logo: logo09,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "한국정보통신기술협회",
            bgImg: icon07,
         },
         {
            title: "심사비용",
            desc: "기능 50개: 1000만원 ~ <br/>추가기능 15개: 80만원",
            bgImg: icon09,
         },
         {
            title: "유효기간",
            desc: "없음",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "1~2개월 소요",
            bgImg: icon11,
         },
         {
            title: "접수기간",
            desc: "상시 접수",
            bgImg: icon13,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "시스템관리 SW: 컴퓨터·응용소프트웨어·네트워크 등 전산환경을 통합적으로 관리해 주는 소프트웨어 등",
                  "침입탐지시스템(IDS), 침입방지시스템(IPS), 방화벽(FW), 안티바이러스·스팸 SW,시스템 및 App 보안 SW, 암호·인증 SW, 블록체인 SW 등",
                  "기업용 SW: ERP, CRM, SCM, 협업 App. 등",
                  "산업특화 SW: 산업 전반에 활용되는 자동화 소프트웨어 등",
                  "서비스 지원용 SW: PIMS, 기기내장 어플리케이션, 기타 업무용 어플리케이션 등",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "기술성 및 경제적 가치가 미흡한 단순기능 소프트웨어 제품",
                  "독자적인 환경에서만 운영되는 범용성이 없는 소프트웨어 제품",
                  "게임산업진흥에 관한 법률에 다른 사행성 소프트 웨어 제품",
                  "건전한 기업활동을 저해하는 위해성 소프트웨어 제품",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "조달청 제3자 단가계약 체결 및 나라장터 등록을 통한 구매기관의 수의계약 지원",
      },
      {
         desc: "GS인증제품을 공공기관 우선구매 대상 기술개발 제품으로 지정",
      },
      {
         desc: "행정 및 공공 정보화사업 구축 운영 시 우선 도입 대상 제품으로 지정",
      },
      {
         desc: "수의계약 체결 및 계약 관리를 위해 제3자단가계약 신청 시 필수 요건으로 지정",
      },
      {
         desc: "상용소프트웨어 직접구매 대상으로 GS인증획득 제품 지정",
      },
      {
         desc: "소프트웨어사업 하도급계약의 적정성 판단시 가점(2점) 부여",
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="examination xl:pt-80 xl:pb-80 bg-subColor11">
            <div className="container xl:flex justify-between">
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div className="xl:block hidden">
                  <img className="main_img w-full xl:mt-24" src={image01} alt="심사절차" />
               </div>
               <ul className="xl:hidden mo_items_wrap">
                  <MoItems01 bgColor="#75c5f5">사전상담요청 및 상담</MoItems01>
                  <MoItems01 bgColor="#48afec">신청서 제출 및 수수료 납부</MoItems01>
                  <MoItems01 bgColor="#2ea4e9">시험평가</MoItems01>
                  <MoItems01 bgColor="#1592dc">품질평가보고서 작성</MoItems01>
                  <MoItems01 bgColor="#1592dc">심의위원회 개최</MoItems01>
                  <MoItems01 bgColor="#1592dc">인증서 수령</MoItems01>
               </ul>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default GsCert;