import styled from "styled-components"

// components
import { Benefits, CertificationInfo, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo from "../../../images/certification/logo23.png"
import image01 from "../../../images/certification/type01-15.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon13 from "../../../images/common/icon/icon13.png"
import { MoItems01 } from "../../../components/StyledComponents"

// style
const Container = styled.div`
   & .examination{
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         max-width: 1020px;
         height: auto;
      }
   }
`

const Iso14001 = () => {

   const overViewInfo = {
      title: "ISO14001",
      desc: "ISO 14001 인증은 귀하의 비즈니스가 환경적으로 책임있고, 브랜드 인지도를 재고하고, 새로운 비즈니스 기회를 가져다 줄 것입니다. <br class='xl:block hidden'/>또한, 귀하가 환경적 영향을 감소하고 지속가능성 활동에 대한 이해관계자들의 기대를 충족하는 데 노력을 아끼지 않는 다는 것을 보여줍니다. ",
      logo: logo,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "국내 및 해외 인정기관에 등록된 인증기관",
            bgImg: icon07,
         },
         {
            title: "심사비용",
            desc: "기업 규모에 따라 상이",
            bgImg: icon08,
         },
         {
            title: "유효기간",
            desc: "발급 후 3년간 유효",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "1~2개월 소요",
            bgImg: icon11,
         },
         {
            title: "접수기간",
            desc: "상시 접수",
            bgImg: icon13,
         },
      ],
   };
   const BenefitsData = [
      {
         desc: "환경 사고의 사전 예방 체제 수립하여 환경비용 최소화",
      },
      {
         desc: "고객의 환경 친화적 상품 구매에 따른 경쟁력 확보",
      },
      {
         desc: "자원 절감으로 수익성 증대",
      },
      {
         desc: "기업 이미지 향상. <br/>대외 홍보 교과 발생",
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <section className="examination xl:pt-80 xl:pb-80">
            <div className="container xl:flex justify-between">
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div>
                  <div className="xl:block hidden">
                     <img className="main_img w-full xl:mt-24" src={image01} alt="심사절차" />
                  </div>
                  <ul className="xl:hidden mo_items_wrap">
                     <MoItems01 bgColor="#75c5f5">신청서류 접수</MoItems01>
                     <MoItems01 bgColor="#48afec">심사일정 협의</MoItems01>
                     <MoItems01 bgColor="#2ea4e9">심사계획 관련 공문 발송</MoItems01>
                     <MoItems01 bgColor="#1592dc">1단계 심사(문서심사)</MoItems01>
                     <MoItems01 bgColor="#1592dc">2단계 심사(현장심사)</MoItems01>
                     <MoItems01 bgColor="#1592dc">인증서 발행</MoItems01>
                  </ul>
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default Iso14001;