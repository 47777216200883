import { useState } from "react";
import axios from "axios";

// Props
import { FormDataProps } from "../../model/formdata";
import styled from "styled-components";

// styles
const Container = styled.div`
   @media screen and (max-width:1280px){
      .top_head_wrap {
         padding-bottom: 40px;
         margin-bottom: 24px;
      }

      .inquire_from .item_01 {
         display: block;
      }

      .inquire_from .input_wrap input,
      .inquire_from .input_wrap textarea {
         width: 100%;
         font-size: 13px;
      }

      .inquire_from .input_wrap input {
         height: 48px;
      }

      .inquire_from .input_wrap.message_wrap textarea {
         height: 175px;
      }

      .inquire_from .input_wrap .label_upload {
         height: 40px;
      }

      .privacy_wrap .info_wrap {
         padding: 24px 16px;
         margin-bottom: 24px;
      }

      .inquire_from .input_wrap.upload_wrap {
         padding-bottom: 24px;
         margin-bottom: 24px;
      }

      .btn_submit {
         width: 100%;
         height: 58px;
      }
   }
`

export default function Contact() {
   const [formData, setFormData] = useState<FormDataProps>({
      name: '',
      contact: '',
      company: '',
      email: '',
      message: '',
      agreement: false,
   });

   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value, type } = e.target;

      if (type === 'checkbox') {
         const { checked } = e.target as HTMLInputElement;
         setFormData(prevFormData => ({
            ...prevFormData,
            [name]: checked,
         }));
      } else {
         setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
         }));
      }
   };


   const handleSubmit = (e: any) => {
      e.preventDefault();
      if (!formData.agreement) {
         alert('개인정보 수집 및 이용에 동의해야 합니다.');
         return;
      }

      const fetchData = async () => {
         try {
            const response: any = await axios.post(`${process.env.REACT_APP_API_URL}/inquiry`, formData)
            if (response.data.success === true) {
               alert('문의가 등록되었습니다.');
               window.location.reload();
            }
            else {
               console.log("일시적인 오류가 발생했습니다")
            }
         } catch (error:any) {
            alert(error.response.data.message)
         }
      }
      fetchData();
   };

   return (
      <Container className="contact_inquire container xl:mb-120">
         <div className="top_head_wrap">
         </div>
         <form onSubmit={handleSubmit} className="inquire_from">
            <fieldset>
               <legend className="hide">문의내용 작성</legend>
               <div className="item_01">
                  <div className="name_wrap input_wrap">
                     <label htmlFor="name" className="xl:mb-16 mb-8 xl:text-20 text-13 font-bold">
                        성함 <strong className="point">*</strong>
                     </label>
                     <input
                        value={formData.name}
                        onChange={handleChange}
                        name="name"
                        id="name"
                        type="text"
                        placeholder="성함을 입력해주세요."
                        required
                     />
                  </div>
                  <div className="phone_wrap input_wrap">
                     <label htmlFor="phone" className="xl:mb-16 mb-8 xl:text-20 text-13 font-bold">
                        연락처 <strong className="point">*</strong>
                     </label>
                     <input
                        type="tel"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                        id="phone"
                        placeholder="연락처를 입력해주세요."
                        required
                     />
                  </div>
               </div>
               <div className="item_01">
                  <div className="company_wrap input_wrap">
                     <label htmlFor="company" className="xl:mb-16 mb-8 xl:text-20 text-13 font-bold">
                        회사명
                     </label>
                     <input
                        value={formData.company}
                        onChange={handleChange}
                        name="company"
                        id="company"
                        type="company"
                        placeholder="회사명을 입력해주세요."
                     />
                  </div>
                  <div className="email_wrap input_wrap">
                     <label htmlFor="email" className="xl:mb-16 mb-8 xl:text-20 text-13 font-bold">
                        이메일 <strong className="point">*</strong>
                     </label>
                     <input
                        value={formData.email}
                        onChange={handleChange}
                        name="email"
                        id="email"
                        type="email"
                        placeholder="이메일을 입력해주세요."
                        required
                     />
                  </div>
               </div>
               <div className="message_wrap input_wrap">
                  <label htmlFor="content" className="xl:mb-16 mb-8 xl:text-20 text-13 font-bold">
                     문의내용 <strong className="point">*</strong>
                  </label>
                  <textarea
                     value={formData.message}
                     onChange={handleChange}
                     name="message"
                     id="content"
                     placeholder="문의내용을 기재해주세요.(필수)"
                     required
                  />
               </div>
               <div className="privacy_wrap">
                  <p className="xl:text-20 text-13 font-bold xl:mb-16 mb-8">개인정보수집 동의</p>
                  <div className="info_wrap xl:mb-16 mb-8">
                     <p className="xl:text-18 text-14 font-medium xl:mb-24 mb-16">개인정보 수집 • 이용 내역</p>
                     <ul className="list_wrap">
                        <li className="item xl:mb-16 mb-8">
                           <p className="tit xl:text-16 text-12 font-light pc_only">항목 (필수항목)</p>
                           <p className="txt xl:text-16 text-12 font-light pc_only">성명, 연락처, 이메일</p>
                        </li>
                        <li className="item xl:mb-16 mb-8">
                           <p className="tit xl:text-16 text-12 font-light pc_only">수집 • 이용 목적</p>
                           <p className="txt md:leading-26 leading-18 xl:text-16 text-12 font-light">고객문의는 최소한의 개인정보만을 수집합니다. 상담내용확인 및 상담결과 회신 서비스 제공을 목적으로 개인정보 수집 이용 동의를 구합니다.</p>
                        </li>
                        <li className="item">
                           <p className="tit xl:text-16 text-12 font-light pc_only">보유기간</p>
                           <p className="txt md:leading-26 leading-18 xl:text-16 text-12 font-light">개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. (단, 타 법령에 의해 보존의무가 있는 경우 법령이 지정한 기간 동안 보존합니다.)</p>
                        </li>
                     </ul>
                  </div>
                  <div className="privacy_agree_wrap mb_80">
                     <input
                        id="privacy_agree"
                        name="agreement"
                        checked={formData.agreement}
                        onChange={handleChange}
                        required
                        type="checkbox"
                     />
                     <label className="xl:text-17 text-13" htmlFor="privacy_agree">
                        개인정보 수집 및 이용에 대한 동의 (필수)
                     </label>
                  </div>
               </div>
               <button className="btn_submit xl:text-18 text-14 font-bold xl:mt-80 mt-42" type="submit">
                  문의하기
               </button>
            </fieldset>
         </form>
      </Container>
   )
}