import { useState, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";

// type
import { SubTopArea } from "../../../model/subPageTopArea"
import { SubPageTab } from "../../../model/subPageTabArea"

// components
import SubPageTopArea from "../../../components/SubPageTopArea"
import SubPageTabArea from "../../../components/SubPageTabArea"

// pages
import CompanyLab from "./CompanyLab";
import Innobiz from "./Innobiz";
import Mainbiz from "./Mainbiz";
import Venture from "./Venture";

// images
import bannerBg04 from "../../../images/certification/banner_bg04.png"

const ManagementCert = () => {
   const location = useLocation();
   const queryString = Number(location.search.substring(1));

   // TopArea, Tab
   const subPageInfo: SubTopArea = {
      title: "인증 분야",
      category: [
         { name: "조달인증", link: "/certification/procurement" },
         { name: "품질인증", link: "/certification/quality-cert" },
         { name: "기술인증", link: "/certification/tech-cert" },
         { name: "경영인증", link: "/certification/management-cert" },
      ],
      bannerText: "우수조달컨설팅은 <span class='point'>확실한 신용과 빅데이터</span>를 활용하여 <br/><span class='point'>정확한 컨설팅</span>을 진행합니다 ",
      currentInfo: {
         name: "경영인증",
         bgImg: bannerBg04,
      },
   };

   const subPageTab: SubPageTab[] = [
      { name: "벤처기업" },
      { name: "이노비즈" },
      { name: "기업부설연구소" },
      { name: "메인비즈" },
   ];

   const [tabIndex, setTabIndex] = useState(queryString ? queryString : 0);

   const components = useMemo(() => [
      <Venture />,
      <Innobiz />,
      <CompanyLab />,
      <Mainbiz />,
   ], []);

   const returnComponent = useCallback(() => {
      return components[tabIndex];
   }, [tabIndex, components]);

   return (
      <div>
         <SubPageTopArea info={subPageInfo} />
         <SubPageTabArea data={subPageTab} setTabIndex={setTabIndex} currentId={queryString} />
         {returnComponent()}
      </div>
   );
}

export default ManagementCert;
