import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, Consulting, ContactBanner, Overview } from "../../../components/SubPageCommonUI"
import { MoItems01 } from "../../../components/StyledComponents"

// images
import logo from "../../../images/certification/logo22.png"
import image01 from "../../../images/certification/type01-05.png"
import image02 from "../../../images/certification/type02-03.png"
import image02Mo from "../../../images/certification/mobile_type02-03.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"

// style
const Container = styled.div`
   & .table_wrap{
      & .table_img{
         width: 100%;
         border-bottom: 1px solid var(--subColor05);
      }
      & .list:not(:last-of-type){
         margin-bottom: 16px;
      }
   }
   & .examination{
      background-color: var(--subColor11);
      & .title{
         white-space: nowrap;
      }
      & .main_img{
        max-width: 1020px;
      }
   }
`

const GPass = () => {

   const overViewInfo = {
      title: "G-PASS",
      desc: "「조달사업에 관한 법률」제28조에 따라 조달청장이 해외조달시장 진출을 지원하기 위하여 국내기업을 선정하여 해외시장진출을 지원하는 제도입니다.",
      logo: logo,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "조달청",
            bgImg: icon07,
         },
         {
            title: "심사비용",
            desc: "없음",
            bgImg: icon08,
         },
         {
            title: "유효기간",
            desc: "5년 <br/>(원하는 경우 심사를 거쳐 재지정) ",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "신청일로부터 약 1~2개월 소요",
            bgImg: icon11,
         },
      ],
      cont_desc: "※ 제품의 품질 및 기술인증, 납품실적, 수의계약 가능 여부, 수출실적 등을 고려하여 최대 3년간 연장 (연장 조건 중복 불가)",
      sub_desc: "※ 4회 탈락 후 재신청 방법: <br />특허 개발 및 변경으로 처음부터 다시 신청 ",
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "국가종합전자조달시스템(www.g2b.go.kr)에 경쟁입찰참가자격을 등록한 중견·중소기업",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "신청에 관한 서류를 위조ㆍ변조 하거나 허위서류를 제출한 경우",
                  "부도, 파산 등이 확인된 경우",
                  "『중소기업제품 구매촉진 및 판로지원에 관한 법률』 제8조의2 제1항에 해당하는 기업인 경우",
                  "부정당업자 제재기간 중에 있는 경우",
                  "그 밖에 G-PASS기업 지정이 곤란하다고 조달청장이 인정하는 경우",
               ],
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "나라장터 엑스포(KOPPEX) <br/>연계 수출 상담회",
      },
      {
         desc: "글로벌 공공조달 수출 상담회(GPPM)",
      },
      {
         desc: "해외 전시회 (단체/개별) 참여 및 <br/>해외시장개척단",
      },
      {
         desc: "해외조달시장진출 온라인 교육 · 설명회",
      },
      {
         desc: "수출 전략기업 육성사업",
      },
      {
         desc: "K-바이오 해외조달시장 진출 <br/>통합지원사업",
      }
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="examination xl:pt-80 xl:pb-80">
            <div className="container xl:flex justify-between">
               <h3 className="title leading-1em xl:text-32 text-24 font-bold xl:mb-0 mb-24">심사절차</h3>
               <img className="xl:block hidden main_img w-full" src={image01} alt="심사절차" />
               <ul className="xl:hidden mo_items_wrap">
                  <MoItems01 bgColor="#75c5f5">G-PASS기업 신청서 제출</MoItems01>
                  <MoItems01 bgColor="#48afec">서류 검토 및 보완</MoItems01>
                  <MoItems01 bgColor="#2ea4e9">현장실태 조사</MoItems01>
                  <MoItems01 bgColor="#1592dc">1, 2차 심사</MoItems01>
                  <MoItems01 bgColor="#1592dc">결과통보</MoItems01>
                  <MoItems01 bgColor="#1592dc">지정서 수여</MoItems01>
               </ul>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <Consulting
            imgSrc={image02}
            moImgSrc={image02Mo}
         />
         <ContactBanner />
      </Container >
   )
}

export default GPass;