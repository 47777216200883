import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import DOMPurify from "dompurify"
import styled from 'styled-components';

// type
import { SubTopArea } from "../model/subPageTopArea"

interface OwnProps {
   info: SubTopArea;
}

// style
const Container = styled.section`
& .btn_category{
   height: 1em;
   border-right: 1px solid var(--subColor04);
}
& .btn_category:last-of-type{
   border-right: 0px none;
}
   & .btn_category.on{
      position: relative;
      font-weight: bold;
      color: var(--subColor01);
   }
   & .btn_category.on::after{
      content: '';
      display: block;
      clear: both;
      width: 100%;
      height: 3px;
      margin-top: 7px;
      background-color: var(--pointColor01);
   }
   & .banner_wrap{
      position: relative;
      height: 380px;
      display: flex;
      background-size: cover;
      background-position: center;
      & .banner_text{
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         width: max-content;
         & .point{
            font-weight: bold;
            color: #fff;
         }
      }
      & .sub_nav{
         display: flex;
         justify-content: flex-end;
         align-items: flex-end;
         gap: 8px;
         margin-top: auto;
         & .nav_item{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
         }
      }
   }
   @media screen and (max-width:1280px){
      .banner_wrap{
         height: 300px;
      }
   }
`

const SubPageTopArea: React.FC<OwnProps> = ({ info }) => {

   const pathname = useLocation().pathname;
   const [categoryWrapIndex, setCategoryWrapIndex] = useState(0);

   const handleMobileCategory = () => {
      setCategoryWrapIndex((prev) => prev === 0 ? 1 : 0)
   }

   return (
      <Container className='xl:mt-200 mt-130'>
         <div className='relative xl:pb-0 pb-40'>
            <h2 className='text-center xl:text-48 text-24 font-bold xl:pb-48 pb-40'>{info.title}</h2>
            <div className={`${categoryWrapIndex === 1 ? "on" : ""} xl:hidden only-mb btn_category_wrap flex justify-center`}>
               {info.category.map((category, index) => (
                  <Link
                     to={category.link}
                     className={`${category.link === pathname ? "on" : ""} btn_category leading-1em xl:text-20 text-14 xl:pr-24 xl:mr-24 text-subColor04`}
                     key={index}
                     onClick={() => handleMobileCategory()}
                  >
                     {category.name}
                     <i className='icon_arrow xl:hidden inline-block xi-angle-down-min'></i>
                  </Link>
               ))}
            </div>
            <div className="btn_category_wrap xl:flex hidden justify-center">
               {
                  info.category.map((category, index) => (
                     <Link
                        to={category.link}
                        className={`${pathname === category.link ? "on" : ""} btn_category leading-1em xl:text-20 text-14 xl:pr-24 xl:mr-24 text-subColor04`}
                        key={index}
                     >
                        {category.name}
                     </Link>
                  ))
               }
            </div>
         </div>
         <div className='banner_wrap xl:pb-40 xl:mt-48' style={{ backgroundImage: `url(${info.currentInfo.bgImg})` }}>
            <h3 className='banner_text text-center xl:text-40 text-24 text-white' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(info.bannerText) }} />
            <ul className='sub_nav container'>
               <li className='nav_item'>
                  <i className='xi-home-o xl:text-17 text-12 text-white'></i>
               </li>
               <li className='nav_item'>
                  <i className='xi-angle-right-min xl:text-17 text-12 text-white'></i>
               </li>
               <li className='nav_item'>
                  <p className='xl:text-16 text-12 font-bold text-white'>{info.title}</p>
               </li>
               <li className='nav_item'>
                  <i className='xi-angle-right-min xl:text-17 text-12 text-white'></i>
               </li>
               <li className='nav_item'>
                  <p className='xl:text-16 text-12 font-bold text-white'>{info.currentInfo.name}</p>
               </li>
            </ul>
         </div>
      </Container>
   )
}

export default SubPageTopArea;