import styled from 'styled-components'

// components
import { Benefits02, CertificationInfo, CheckList, Consulting, ContactBanner, Overview } from '../../../components/SubPageCommonUI'

// images
import logo from "../../../images/certification/logo17.png"
import image01 from "../../../images/certification/type01-18.png"
import image02 from "../../../images/certification/type02-04.png"
import image02Mo from "../../../images/certification/mobile_type02-04.png"
import table01 from "../../../images/certification/table11.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import { MoItems01 } from '../../../components/StyledComponents'

// styles
const Container = styled.div`
   & .main_img{
      max-width: 1020px;
   }
   & .benefits{
      background-color: var(--subColor11);
      & .item{
         background-color: #fff;
      }
   }
   @media screen and (max-width:1280px){
      .cost{
         .main_img_wrap{
            .main_img{
               width: auto;
               max-width: none;
               height: 360px;
            }
         }
      }
   }
`

const Venture = () => {

   const overViewInfo = {
      title: "벤처기업",
      desc: "「벤처기업육성에 관한 특별조치법」에 규정된 일정 요건을 갖추고 기술의 혁신성과 사업의 성장성이 우수한 기업을 벤처기업으로 발굴하고 지원하기 위한 제도입니다. 1998년 시행 이후 지속적인 법 개정을 거쳐, 2021년 2월 12일 혁신성·성장성 중심의 ‘민간주도 벤처기업확인제도’로 전면 개편되어 시행 중이다",
      logo: logo,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "중소벤처기업부",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "3년",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "유형별로 접수완료일로부터 30일 ~ 45일 소요",
            bgImg: icon11,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "※ 벤처투자유형",
               desc_text: [
                  "중소기업",
                  "적격투자기관으로부터 유치한 투자금액 합계 5천만원 이상",
                  "자본금 중 투자금액의 합계가 차지하는 비율 10% 이상",
               ],
            },
            {
               desc_title: "※ 연구개발유형",
               desc_text: [
                  "중소기업",
                  "기업부설연구소 / 연구개발전담부서 / 기업부설창작연구소 / 기업창작전담부서 중 1개 이상 보유",
                  `벤처기업확인 요청일이 속하는 분기의 직전 4개 분기 연구개발비가 5천만원 이상이고, 같은 기간 총매출액 중 연구개발비의 합계가 차지하는 비율이 5% 이상<br/>
                  <span class='block xl:pt-12 text-subColor03'>※ 창업 3년 미만일 경우, 연간 매출액 중 연구개발비 비율 미적용</span>`,
                  "벤처기업확인기관으로부터 사업의 성장성이 우수한 것으로 평가받은 기업",
               ],
            },
            {
               desc_title: "※ 혁신성장유형",
               desc_text: [
                  "중소기업",
                  "벤처기업확인기관으로부터 기술의 혁신성과 사업의 성장성이 우수한 것으로 평가 받은 기업",
               ],
            },
            {
               desc_title: "※ 예비벤처유형",
               desc_text: [
                  "법인 또는 개인사업자 등록을 준비 중인 자 ",
                  "벤처기업확인기관으로부터 기술의 혁신성과 사업의 성장성이 우수한 것으로 평가받은 자",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "일반 유흥 주점업 (분류코드 56211)",
                  "모두 유흥 주점업 (분류코드 56212)",
                  "기타 주점업 (분류코드 56219)",
                  "블록체인 기반 임호화 자산 매매 및 중개업 (분류코드 63999-1)",
                  "기타 사행시설 관리 및 운영업 (분류코드 91249)",
                  "무도장 운영업 (분류코드 91291)",
               ]
            },
         ]
      },
   }
   const Benefits02Data = [
      {
         title: "※ 세제",
         items: [
            "법인세 · 소득세 최초 벤처확인일로부터 최대 5년간 50% 감면",
            "취득세 75% 감면",
            "재산세 최초 벤처확인일로부터 3년간 면제, 이후 2년간 50% 감면",
         ],
      },
      {
         title: "※ 금융",
         items: [
            "기술보증기금 보증한도 확대",
            "코스닥상장 심사기준 우대",
         ],
      },
      {
         title: "※ 입지",
         items: [
            "취득세 50%, 재산세 35% 경감",
            "취득세(2배), 등록면허세(3배), 재산세(5배) 중과 적용 면제",
         ],
      },
      {
         title: "※ M&A",
         items: [
            "대기업이 벤처기업을 인수·합병하는 경우 상호출자제한기업집단으로의 계열편입을 7년간 유예",
         ],
      },
      {
         title: "※ 인력",
         items: [
            "기업부설연구소 또는 연구개발전담부서의 인정기준 완화",
            "기업부설창작연구소 또는 기업창작전담부서의 인정기준 완화",
            "스톡옵션 부여 대상 확대",
            "총 주식수 대비 스톡옵션 부여 한도 확대",
         ],
      },
      {
         title: "※ 광고",
         items: [
            "TV·라디오 광고비 3년간 최대 70% 할인 : 정상가 기준 35억원 (105억/3년) 한도",
            "TV·라디오 광고제작비 지원(택 1) : TV 최대 50% · 라디오 최대 70% 지원",
         ],
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList info={CheckListData} />
         <section className='cost xl:pt-80 xl:pb-80 bg-subColor11'>
            <div className="container">
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사비용</h3>
               <div className='main_img_wrap type_01'>
                  <img className='main_img w-full xl:mt-48' src={table01} alt="심사비용" />
               </div>
            </div>
         </section>
         <section className='xl:pt-80 xl:pb-80 pt-60 pb-60'>
            <div className='container xl:flex justify-between'>
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <img className='xl:block hidden main_img' src={image01} alt="심사 절차" />
               <ul className="xl:hidden mo_items_wrap">
                  <div>
                     <MoItems01 bgColor="#75c5f5">확인유형 선택 후 신청</MoItems01>
                     <p className='leading-18 text-12 text-subColor03'>ㆍ나에게 맞는 벤처유형 또는 유형별 안내를 통해 가장 적합한 유형 선택</p>
                  </div>
                  <div>
                     <MoItems01 bgColor="#48afec">접수</MoItems01>
                     <MoItems01 bgColor="#2ea4e9">전문평가기관 평가</MoItems01>
                     <p className='leading-18 text-12 text-subColor03'>
                        ㆍ전문평가기관 및 평가시험에 따라 업종/지역별로 최적화 된
                        전문평가기관 자동배정
                     </p>
                  </div>
                  <div>
                     <MoItems01 bgColor="#1592dc">확인위원회 심의</MoItems01>
                     <p className='leading-18 text-12 text-subColor03'>ㆍ확인평가에 불복하는 경우, 통보 받은 날부터 30일 이내 이의신청 가능</p>
                  </div>
                  <MoItems01 bgColor="#1592dc">확인서 발급</MoItems01>
               </ul>
            </div>
         </section>
         <Benefits02 info={Benefits02Data} />
         <Consulting
            imgSrc={image02}
            moImgSrc={image02Mo}
         />
         <ContactBanner />
      </Container>
   )
}

export default Venture