import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import image01 from "../../../images/certification/type03-01.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon13 from "../../../images/common/icon/icon13.png"

// style
const Container = styled.div`
   & .target_wrap{
      & .title{
         white-space: nowrap;
      }
      & .item_wrap{
         width: 100%;
      }
   }
   & .examination{
      background-color: var(--subColor11);
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         width: 100%;
         height: auto;
      }
   }
   @media screen and (max-width:1280px){
      .procedure{
         .main_img_wrap{
            .main_img{
               width: auto;
               height: 790px;
               max-height: calc(100vh - 60px);
            }
         }
      }
   }
`

const MultiSupplier = () => {

   const overViewInfo = {
      title: "다수공급자계약(MAS)",
      desc: "각 공공기관의 다양한 수요를 충족하기 위하여 품질, 성능, 효율 등에서 동등하거나 유사한 종류의 물품을 수요기관이 선택할 수 있도록 2인 이상의 계약상대자로 하는 계약제도로서 납품실적, 경영상태 등이 일정한 기준에 적합한 자를 대상으로 협상을 통해 계약하고 수요고객이 쇼핑몰에서 자유롭게 물품을 선택하는 제도입니다.",
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "조달청",
            bgImg: icon07,
         },
         {
            title: "심사비용",
            desc: "없음",
            bgImg: icon08,
         },
         {
            title: "유효기간",
            desc: "2~3년(공고별로 다름)",
            bgImg: icon09,
         },
         {
            title: "처리기간",
            desc: "최소 2개월~3개월 소요",
            bgImg: icon11,
         },
         {
            title: "접수기간",
            desc: "다수공급자 구매공고 신청기간 내",
            bgImg: icon13,
         },
      ],
      // cont_desc: "※ 특허·실용신안 만료일자나 규제샌드박스 심의 기간이 지정일로부터 3년 이내일 경우 해당 지정일까지를 지정기간으로 함",
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "규격(모델)이 확정되고 상용화된 물품 (연간 납품실적이 3천만원 이상인 업체가 3개사 이상,업체공통의 상용규격 및 시험기준이 존재할 것)",
                  "단가계약 (제3자 단가계약 포함)이 가능한 물품",
                  "기타 조달청장이 필요하다고 판단하는 물품 등",
               ],
            },
         ],
      }
   }
   const BenefitsData = [
      {
         desc: "공공 조달시장 진출",
      },
      {
         desc: "기업 신뢰도 제고",
      },
      {
         desc: "안정된 판매처 확보",
      },
      {
         desc: "기타 연계지원 혜택",
      }
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="procedure bg-subColor11 xl:pt-80 xl:pb-80 pt-60 pb-60">
            <div className="container">
               <h3 className="leading-1em xl:text-32 text-24 font-bold xl:mb-56 mb-24">심사절차</h3>
               <div className="main_img_wrap type_01">
                  <img className="main_img w-full" src={image01} alt="심사절차" />
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default MultiSupplier;