import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo08 from "../../../images/certification/logo08.png"
import image01 from "../../../images/certification/type01-10.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon13 from "../../../images/common/icon/icon13.png"
import { MoItems01 } from "../../../components/StyledComponents"

// style
const Container = styled.div`
   & .examination{
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         max-width: 1020px;
         height: auto;
      }
   }
`

const GrCert = () => {

   const overViewInfo = {
      title: "GR인증",
      desc: "국내에서 개발·생산된 재활용제품을 철저히 시험·분석·평가한 후 우수제품에 대하여 품질을 평가하여 인증하는 제도입니다. 제품별 품질인증기준을 제정하여 제품생산 전 과정에서의 종합적 품질관리시스템 뿐만 아니라 품질 및 성능, 제품의 환경성이 우수한 재활용제품에 대하여 GR인증 부여합니다.",
      logo: logo08,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "자원순환산업인증원",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "3년 <br />(3년 단위로 재연장 가능)",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "3개월",
            bgImg: icon11,
         },
         {
            title: "접수기간",
            desc: "상시 접수",
            bgImg: icon13,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "국내에서 발생한 재활용 가능자원을 활용한 제품 중 품질 및 환경친화성이 우수하고 에너지 절약 등 재활용 파급효과가 큰 우수재활용제품",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "단순가공 제품, 재사용 제품, 재자원화가 어렵거나 2차적인 환경오염이 우려되는 등 환경친화성이 낮은 제품 및 국민생활을 해칠 우려가 있는 제품",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "공공기관 녹색제품 의무구매",
      },
      {
         desc: "조달청 인센티브",
      },
      {
         desc: "중소벤처기업부 인센티브",
      },
      {
         desc: "정부포상 지원",
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="examination xl:pt-80 xl:pb-80">
            <div className="container xl:flex justify-between">
            <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div className="xl:block hidden">
                  <img className="main_img w-full xl:mt-24" src={image01} alt="심사절차" />
               </div>
               <ul className="xl:hidden mo_items_wrap">
                  <MoItems01 bgColor="#75c5f5">신청서 접수 및 검토</MoItems01>
                  <MoItems01 bgColor="#48afec">서류·면접 심사</MoItems01>
                  <MoItems01 bgColor="#2ea4e9">현장심사</MoItems01>
                  <MoItems01 bgColor="#1592dc">제품심사</MoItems01>
                  <MoItems01 bgColor="#1592dc">종합검사</MoItems01>
                  <MoItems01 bgColor="#1592dc">인증서 수여식</MoItems01>
               </ul>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default GrCert;