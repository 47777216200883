import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo10 from "../../../images/certification/logo10.png"
import image01 from "../../../images/certification/type01-12.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon13 from "../../../images/common/icon/icon13.png"
import { MoItems01 } from "../../../components/StyledComponents"

// style
const Container = styled.div`
   & .examination{
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         max-width: 1020px;
         height: auto;
      }
   }
`

const GroupCert = () => {

   const overViewInfo = {
      title: "단체표준인증",
      desc: "단체표준표시인증은 KS표시 인증과 같이 표준화 및 품질경영을 도입하여 전사적 시스템으로 관리하고 있는지를 해당인증단체(조합 or 협회)에서 자체적으로 정한 심사기준 및 인증업무 규정에 의거, 심사한 후 합격된 업체에 대하여인증을 하는 제도입니다.",
      logo: logo10,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "한국표준협회",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "1년 <br/>(단체 or 협회 마다 상이)",
            bgImg: icon09,
         },
         {
            title: "접수기간",
            desc: "상시 접수",
            bgImg: icon13,
         },
         {
            title: "처리기간",
            desc: "1~2개월 소요",
            bgImg: icon11,
         },
         {
            title: "심사비용",
            desc: "공장+제품심사 수수료 및 심사원수당, 교통비",
            bgImg: icon09,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "※ 단체표준과 심사기준이 제정되어 있는 단체표준 제품을 생산 제조업체를 대상으로 한다.",
               desc_text: [
                  "단체표준 인증업무를 위한 전담조직을 갖출 것",
                  "단체표준 인증심사원을 2명 이상 확보할 것(이 경우 1명은 그 단체에 소속된 인증심사원이어야 함)",
                  "단체표준 인증에 관한 국제기준에 적합하게 작성된 단 체표준 인증 업무규정을 보유할 것",
                  "단체표준 인증업무 외의 업무를 수행하고 있는 경우 그 업무를 함으로써 단체표준 인증업무가 불공정하게 수행될 우려가 없을 것",
                  "단체표준 인증업무에 필요한 시험설비와 그 설비를 운용하는 제품 검사원을 확보할 것",
               ],
            },
         ],
      },
      negative: {
         title: "심사 비용",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "공장심사수수료: 70만원(품목추가시 품목당 25만원)",
                  "제품심사수수료: 30만원",
                  "공장+제품심사수수료: 1품목일시 95만원(공장심사수수료+품목당 추가수수료 25만원), 2품목 이상일시 품목별 추가수수료 25만원 가산",
                  "인증 심사원 수당 : 30만원/ 1인 1일",
                  "교통비 : 별도",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "국가 기관, 지방 자치단체, 정부 투자기관 및 정부 조달 시 우선 구매 혜택",
      },
      {
         desc: "가구분야 다수공급자계약 신청시 KS 또는 단체표준 인증 필수화에 대응",
      },
      {
         desc: "단체표준 인증제품에 대하여 조달시장 진입 시 시험성적서 제출 면제",
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="examination xl:pt-80 xl:pb-80 bg-subColor11">
            <div className="container xl:flex justify-between">
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div className="xl:block hidden">
                  <img className="main_img w-full xl:mt-24" src={image01} alt="심사절차" />
               </div>
               <ul className="xl:hidden mo_items_wrap">
                  <MoItems01 bgColor="#75c5f5">신청서 작성 및 접수</MoItems01>
                  <MoItems01 bgColor="#48afec">공장심사</MoItems01>
                  <MoItems01 bgColor="#2ea4e9">제품심사</MoItems01>
                  <MoItems01 bgColor="#1592dc">심사보고서 검토</MoItems01>
                  <MoItems01 bgColor="#1592dc">인증위원회 심의</MoItems01>
                  <MoItems01 bgColor="#1592dc">인증서 발급</MoItems01>
               </ul>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default GroupCert;