import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"
import { MoItems01 } from "../../../components/StyledComponents"

// images
import logo from "../../../images/certification/logo04.png"
import image01 from "../../../images/certification/type01-06.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon13 from "../../../images/common/icon/icon13.png"


// style
const Container = styled.div`
   & .target_wrap{
      & .title{
         white-space: nowrap;
      }
      & .item_wrap{
         width: 100%;
      }
   }
   & .examination{
      background-color: var(--subColor11);
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         width: 100%;
         height: auto;
      }
   }
   & .benefits{
    background-color: var(--subColor11);
   }
`

const VentureRegistration = () => {

   const overViewInfo = {
      title: "벤처나라 등록",
      desc: "다수공급자계약, 우수조달물품 등 일정 조건을 만족하기 어려운 창업․벤처기업 제품을 선도적으로 구매하여 <br class='xl:block hidden' />판로개척과 성장토대 구축을 지원하기 위한 전용 상품몰입니다.",
      logo: logo,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "조달청",
            bgImg: icon07,
         },
         {
            title: "심사비용",
            desc: "없음",
            bgImg: icon08,
         },
         {
            title: "유효기간",
            desc: "인증 취득일로부터 3년",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "동일한 식별번호로 4회 ",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "약 4개월",
            bgImg: icon11,
         },
         {
            title: "접수기간",
            desc: "상시 접수",
            bgImg: icon13,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "다음 중 어느 하나에 해당하는 기업이 직접 생산하거나 국내 * 제조 기업과 주문자상표부착생산(OEM)* 방식으로 생산하는 물품 및 서비스",
               desc_text: [
                  "벤처기업 :「벤처기업육성에 관한 특별조치법」제2조의2의 요건을 갖춘 자* 온라인 신청 시 벤처기업 확인서 제출(지정일 기준-벤처나라 규정 제7조의 2, 5항)",
                  "창업기업 :「중소기업창업 지원법」제2조 제2호에 따라 중소기업을 창업하는 자, 중소기업을 창업하여 사업을 개시한 날로부터 7년이 지나지 않은 자(사업자등록증의 개업연월일 확인)",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "휴․폐업, 부도, 파산 또는 부정당업자 제재 중인 업체",
                  "「중소기업기본법」 제2조 제1항에 따른 중소기업에 해당하지 않거나 「중소기업제품 구매촉진 및 판로지원에 관한 법률」 제8조의2 제1항에 해당하는 업체",
                  "단가계약 또는 카탈로그계약을 체결하여 나라장터 종합쇼핑몰 또는 디지털서비스몰에 등록한 물품 및 서비스와 동일한 물품식별번호 상품",
                  "음․식료품류, 동․식물류, 농․수산물류, 무기․총포․ 화약류와 그 구성품, 유류 및 의약품(농약), 소비재 완성품이 아닌 반제품 등 제6조에 명시한 사유",
                  "지정취소 후 6개월 이내에 벤처창업기업제품으로 재신청된 경우",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "벤처나라-나라장터 연계를 통한 공공기관의 구매 절차 지원",
      },
      {
         desc: "조달청 벤처창업기업제품(벤처나라) 지정 이후 지정증서 발급",
      },
      {
         desc: "조달교육원의 우수한 교육 및 컨설팅 정보 제공",
      },
      {
         desc: "G-PASS 제도를 통한 해외조달시장 진출 지원",
      },
      {
         desc: "전담관제 지정을 통한 공공조달시장 진입 맞춤형 서비스 제공",
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="xl:pt-80 xl:pb-80 pt-60 pb-60">
            <div className="container">
               <h3 className="leading-1em xl:text-32 text-24 font-bold xl:mb-56 mb-24">심사절차</h3>
               <img className="xl:block hidden w-full" src={image01} alt="심사절차" />
               <ul className="xl:hidden mo_items_wrap">
                  <MoItems01 bgColor="#75c5f5">물품식별번호 부여받기</MoItems01>
                  <MoItems01 bgColor="#48afec">온라인신청&#40;벤처나라&#41;</MoItems01>
                  <p className="text-12 mb-8 text-subColor03">ㆍ물품식별번호 부여 후, 벤처나라에서 직접 온라인신청</p>
                  <MoItems01 bgColor="#2ea4e9">조달청 기술‧품질 평가</MoItems01>
                  <p className="text-12 mb-8 text-subColor03">ㆍ신청 상품에 대해 기술·품질평가, 조달적합여부 등 심사</p>
                  <MoItems01 bgColor="#1592dc">공공수요 적합성 심사</MoItems01>
                  <MoItems01 bgColor="#1592dc">지정 결과발표</MoItems01>
                  <MoItems01 bgColor="#0e8dd7">벤처나라 상품 등록</MoItems01>
               </ul>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default VentureRegistration;