import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo12 from "../../../images/certification/logo12.png"
import image01 from "../../../images/certification/type01-13.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon13 from "../../../images/common/icon/icon13.png"
import { MoItems01 } from "../../../components/StyledComponents"

// style
const Container = styled.div`
   & .examination{
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         max-width: 1020px;
         height: auto;
      }
   }
`

const SMark = () => {

   const overViewInfo = {
      title: "S마크인증",
      desc: "제품의 안정성과 신뢰성 그리고 제품의 안전 설계 및 제조를 위한 <br class='xl:block hidden'/>제조사의 품질관리 체제를 심사하여 안전인증기준에 부합할 경우 안전인증증표 입니다.",
      logo: logo12,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "산업안전보건인증원",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "없음",
            bgImg: icon09,
         },
         {
            title: "접수기간",
            desc: "상시 접수",
            bgImg: icon13,
         },
         {
            title: "처리기간",
            desc: "최대 6개월",
            bgImg: icon11,
         },
         {
            title: "심사비용",
            desc: "심사별 가격 상이",
            bgImg: icon08,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "※ 산업용 기계·기구류",
               desc_text: [
                  "CNC선반, 밀링기 등 공작기계류",
                  "전동지게차 등 운반기계류",
                  "반도체·LCD 제조장비(전·후 공정장비, 조립장비, 시험·검사장비, 기타 제조관련 장비)",
                  "로봇 등 자동화 설비류",
                  "기타 산업용 기계·기구류",
               ],
            },
            {
               desc_title: "※ 산업용기계·기구의 부품류",
               desc_text: [
                  "센서류, 차단기류, 게이지류, 산업용 컴퓨터 및 관련기기, 슬링류, 안전부품류 등",
               ],
            },
         ],
      },
      negative: {
         title: "심사 비용",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "※ 서면심사",
               desc_text: [
                  "기계·기구류: 50만원/제품",
                  "부품류: 12.5만원/제품",
               ]
            },
            {
               desc_title: "※ 제품심사",
               desc_text: [
                  "기계·기구류: 50만원/제품 (추가시 12.5만원/모델)",
                  "부품류: 12.5만원/제품 (추가시 6.2만원/모델)",
               ]
            },
            {
               desc_title: "※ 기술능력 및 생산체계심사",
               desc_text: [
                  "12.5만원/제조사업장·제품",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "안전인증제품 구매자에게 산업재해예방시설자금 융자 우선지원대상 선정",
      },
      {
         desc: "국내 공공기업, 대기업 등에 구매 추천",
      },
      {
         desc: "각종 매체를 통하여 인증제품 홍보",
      },
      {
         desc: "인증제품, 제품의 포장에 S마크 표시, 광고",
      },
      {
         desc: "제조물책임(PL) 제도에 대응한 대책 수립 가능",
      },
      {
         desc: "유럽연합 CE마크 인증을 동시에 취득 가능",
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="examination xl:pt-80 xl:pb-80">
            <div className="container xl:flex justify-between">
            <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div>
                  <div className="xl:block hidden">
                     <img className="main_img w-full xl:mt-24" src={image01} alt="심사절차" />
                  </div>
                  <ul className="xl:hidden mo_items_wrap">
                     <MoItems01 bgColor="#75c5f5">상담 및 예비심사신청서 접수</MoItems01>
                     <MoItems01 bgColor="#48afec">결과통지서 송부 및 신청서 접수</MoItems01>
                     <MoItems01 bgColor="#2ea4e9">서면심사</MoItems01>
                     <MoItems01 bgColor="#1592dc">기술능력, 생산체계심사</MoItems01>
                     <MoItems01 bgColor="#1592dc">제품심사</MoItems01>
                     <MoItems01 bgColor="#1592dc">인증서 발급 및 사후관리</MoItems01>
                  </ul>
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default SMark;