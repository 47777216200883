import { Outlet, useLocation } from "react-router-dom";

// type
import { SubTopArea } from "../../model/subPageTopArea"

// components
import SubPageTopArea from "../../components/SubPageTopArea"

// images
import bannerBg05 from "../../images/certification/banner_bg05.png"

const OneStopCommon = () => {

   // TopArea
   const pathname = useLocation().pathname;
   const CurrentTitle = () => {
      if (pathname === "/one-stop/service-info") {
         return "서비스 내용"
      } else if (pathname === "/one-stop/details") {
         return "세부내용"
      } else if (pathname === "/one-stop/service-staff") {
         return "서비스 인력"
      }
   }
   const subPageInfo: SubTopArea = {
      title: "원스톱 서비스",
      category: [
         { name: "서비스 내용", link: "/one-stop/service-info" },
         { name: "세부내용", link: "/one-stop/details" },
         { name: "서비스 인력", link: "/one-stop/service-staff" },
      ],
      bannerText: "우수조달컨설팅은 <span class='point'>확실한 신용과 빅데이터</span>를 활용하여 <br/><span class='point'>정확한 컨설팅</span>을 진행합니다 ",
      currentInfo: {
         name: CurrentTitle(),
         bgImg: bannerBg05,
      },
   };

   return (
      <div>
         <SubPageTopArea info={subPageInfo} />
         <Outlet />
      </div>
   );
}

export default OneStopCommon;
