import { Outlet, useLocation } from "react-router-dom"

// Components
import { SubTopArea } from "../../model/subPageTopArea";
import SubPageTopArea from "../../components/SubPageTopArea";

// Images
import bannerBg01 from "../../images/company/banner_bg01.webp"
import { useEffect, useState } from "react";

const Company = () => {

   const pageTitles: { [key: string]: string } = {
      "/company/about": "우수조달컨설팅소개",
      "/company/certified-company": "인증성공업체",
      "/company/youtube": "유튜브소개",
      "/company/consultant": "컨설턴트 소개",
      "/company/contact": "찾아오시는 길"
   };

   const pathname = useLocation().pathname;
   const [currentName, setCurrentName] = useState<string>(pageTitles[pathname]);

   useEffect(() => {
      setCurrentName(pageTitles[pathname] || "");
   }, [pathname]);

   // TopArea, Tab
   const subPageInfo: SubTopArea = {
      title: "회사소개",
      category: [
         { name: "우수조달컨설팅소개", link: "/company/about" },
         { name: "인증성공업체", link: "/company/certified-company" },
         { name: "유튜브소개", link: "/company/youtube" },
         { name: "컨설턴트 소개", link: "/company/consultant" },
         { name: "찾아오시는 길", link: "/company/contact" },
      ],
      bannerText: "최고 실적과 함께하는 <span class='point'>인증의 명가,</span> <br/>당신의 성공 파트너",
      currentInfo: {
         name: currentName,
         bgImg: bannerBg01,
      },
   }

   return (
      <div>
         <SubPageTopArea
            info={subPageInfo}
         />
         <Outlet />
      </div>
   )
}

export default Company