import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

// images
import logo from "../images/common/logo.png"
import logoWhite from "../images/common/logo_white.png"
import { useEffect, useState } from "react";

// styled
const Container = styled.header`
   z-index: 9980;
   position: fixed;
   top: 0px;
   left: 0px;
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   width: 100%;
   height: 80px;
   border-bottom: 1px solid rgba(255,255,255,0.3);
   & *{
      color: #fff;
   }
   &.on{
      border-bottom: 1px solid var(--subColor05);
      background-color: #fff;
   }
   &.on *{
      color: var(--subColor01);
   }
   &.on .btn_menu_wrap{
      & .btn_menu{
         color: var(--subColor01);
      }
   }
   & .logo_normal{
      display: none;
   }
   & .logo_white{
      display: block;
   }
   &.on .logo_normal{
      display: block;
   }
   &.on .logo_white{
      display: none;
   }
   & .tel_wrap{
      line-height: 1em;
      border: 1px solid #fff;
      border-radius: 9999px;
      padding: 12px 24px;
      color: #fff;
      & *{
         color: #fff;
      }
   }
   &.on .tel_wrap{
      border-color: var(--pointColor01);
      background-color: var(--pointColor01);
   }
   & .right_item_wrap{
      height: 80px;
   }
   @media screen and (min-width:1280px){
      &.hover{
         height: auto;
         background-color: #fff;
         & .logo_normal{
            display: block;
         }
         & .logo_white{
            display: none;
         }
         & .item_dep02_wrap{
            display: block;
         }
         & .tel_wrap{
            border-color: var(--pointColor01);
            background-color: var(--pointColor01);
         }
      }
      &.hover *{
         color: var(--subColor01);
      }
      &.hover .tel_wrap{
         color: #fff;
      }
      &.hover .tel_wrap *{
         color: #fff;
      }
   }
   @media screen and (max-width:1500px){
      *{
         font-size: 15px;
      }
      .category_dep01,
      .category_dep02{
         width: 150px;
      }
      .tel_wrap {
         white-space: nowrap;
      }
   }
   @media screen and (max-width:1280px){
      height: 50px;
      .btn_menu_wrap {
         .btn_menu{
            font-size: 26px;
         }
      }
      &.open{
         height: auto;
      }
      &.open .category_wrap{
         /* overflow-y: scroll; */
         display: block;
         position: absolute;
         top: 50px;
         left: 0px;
         width: 100%;
         height: calc(100vh - 50px);
         padding-top: 24px;
         background-color: #fff;
         .category_dep01, .category_dep02{
            justify-content: flex-start;
            width: 100%;
            height: 45px;
            text-align: left;
            padding: 0px 16px;
         }
         .category_dep01{
            font-weight: 600;
            &.on+.item_dep02_wrap{
               display: block;
            }
            &.on>.icon_arrow{
               transform: rotate(180deg);
            }
         }
         .category_dep02{
            height: 40px;
            padding-left: 24px;
            font-size: 13px;
         }
      }
      .right_item_wrap{
         height: 50px;
         .btn_menu_wrap {
            width: 50px;
            height: 50px;
         }
      }
   }
`;
const CategoryWrap = styled.ul` 
   display: flex;
   & .item_dep02_wrap{
      display: none;
   }
   @media screen and (max-width:1280px){
      display: none;
   }
`;
const Logo = styled(Link)`
   display: flex;
   justify-content: center;
   align-items: center;
   width: auto;
   height: 80px;
   @media screen and (max-width:1280px){
      height: 50px;
   }
`
const CategoryItemDep01 = styled.p`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 200px;
   height: 80px;
`;
const CategoryItemDep02 = styled(Link)`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 200px;
   height: 50px;
`;
const MenuIcon = styled.button`
   width: 80px;
   height: 80px;
`

export default function Header() {

   const pathname = useLocation().pathname
   const [headerActive, setHeaderActive] = useState<number>(0);
   const [categoryIndex, setCategoryIndex] = useState<number | string>('');
   const isMobile = document.documentElement.clientWidth < 1280 ? true : false;
   const [isHover, setIsHover] = useState(false);

   const headerIndex = () => {
      setHeaderActive((prev => prev === 0 ? 1 : 0))
   }

   useEffect(() => {

      const header = document.getElementById("header")

      const btnMenu = document.querySelector(".btn_menu_wrap")
      if (btnMenu) {
         btnMenu.addEventListener("click", () => {
            header?.classList.add("on", "mouseover")
         })
      }

      // const categoryDep01 = document.querySelectorAll(".category_dep01");
      // if (categoryDep01) {
      //    categoryDep01.forEach((element, index) => {
      //       element.addEventListener("click", () => {
      //          setCategoryIndex((prev) => prev === index ? '' : index)
      //       })
      //    })
      // }


   }, [isMobile])
   
   // test
   const categoryItemClick = (index:number) => {
      setCategoryIndex((prev) => prev === index ? '' : index)
   }
   // test

   useEffect(() => {
      const header = document.getElementById("header")

      window.scrollTo(0, 0);
      header?.classList.remove("open")
      setIsHover(false)
      setHeaderActive(0)

   }, [pathname]);

   return (
      <Container
         id="header"
         className={`${headerActive === 1 ? "open" : ""} ${isHover === true ? "hover" : ""} on`}
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => setIsHover(false)}
      >
         <Logo className="xl:ml-40 ml-16" to="/">
            <img className="xl:h-48 h-34 logo_normal" src={logo} alt="logo" />
            <img className="xl:h-48 h-34 logo_white" src={logoWhite} alt="logo" />
         </Logo>
         <CategoryWrap className="category_wrap">
            <li>
               <CategoryItemDep01
                  className={`${categoryIndex === 0 ? "on" : ""} category_dep01 xl:text-20`}
                  onClick={() => {categoryItemClick(0)}}
               >
                  회사소개
                  <i className="icon_arrow xl:hidden inline ml-auto text-15 xi-angle-down-min"></i>
               </CategoryItemDep01>
               <ul className="item_dep02_wrap">
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/company/about">우수조달컨설팅 소개</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/company/certified-company">인증성공업체</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/company/youtube">유튜브 소개</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/company/consultant">컨설턴트 소개</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/company/contact">찾아오시는 길</CategoryItemDep02>
                  </li>
               </ul>
            </li>
            <li>
               <CategoryItemDep01
                  className={`${categoryIndex === 1 ? "on" : ""} category_dep01 xl:text-20`}
                  onClick={() => {categoryItemClick(1)}}
               >
                  인증 분야
                  <i className="icon_arrow xl:hidden inline ml-auto text-15 xi-angle-down-min"></i>
               </CategoryItemDep01>
               <ul className="item_dep02_wrap">
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/certification/procurement">조달인증</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/certification/quality-cert">품질인증</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/certification/tech-cert">기술인증</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/certification/management-cert">경영인증</CategoryItemDep02>
                  </li>
               </ul>
            </li>
            <li>
               <CategoryItemDep01
                  className={`${categoryIndex === 2 ? "on" : ""} category_dep01 xl:text-20`}
                  onClick={() => {categoryItemClick(2)}}
               >
                  원스톱 서비스
                  <i className="icon_arrow xl:hidden inline ml-auto text-15 xi-angle-down-min"></i>
               </CategoryItemDep01>
               <ul className={`${categoryIndex === 2 ? "on" : ""} item_dep02_wrap`}>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/one-stop/service-info">서비스 내용</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/one-stop/details">세부 내용</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/one-stop/service-staff">서비스 인력</CategoryItemDep02>
                  </li>
               </ul>
            </li>
            <li>
               <CategoryItemDep01
                  className={`${categoryIndex === 3 ? "on" : ""} category_dep01 xl:text-20`}
                  onClick={() => {categoryItemClick(3)}}
               >
                  고객지원
                  <i className="icon_arrow xl:hidden inline ml-auto text-15 xi-angle-down-min"></i>
               </CategoryItemDep01>
               <ul className="item_dep02_wrap">
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/customer/notice">공지사항</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/customer/information">정보공유</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/customer/careers">채용정보</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/customer/faq">자주묻는 질문</CategoryItemDep02>
                  </li>
                  <li>
                     <CategoryItemDep02 className="category_dep02" to="/customer/contact">문의하기</CategoryItemDep02>
                  </li>
               </ul>
            </li>
         </CategoryWrap>
         <div className="right_item_wrap flex items-center xl:gap-20 xl:pr-40">
            <a
               className="tel_wrap xl:flex hidden items-center xl:gap-8 xl:text-18"
               href="tel:031-234-2870"
            >
               <i className="xi-call"></i>
               031-234-2870
            </a>
            <MenuIcon onClick={() => headerIndex()} className="btn_menu_wrap xl:hidden block">
               <i className="btn_menu xi-align-right text-white xl:text-30 text-26"></i>
            </MenuIcon>
         </div>
      </Container>
   )
}