import styled from 'styled-components'

// components
import { Benefits02, CertificationInfo, CheckList, Consulting, ContactBanner, Overview } from '../../../components/SubPageCommonUI'

// images
import logo from "../../../images/certification/logo19.png"
import image01 from "../../../images/certification/type01-21.png"
import image02 from "../../../images/certification/type02-07.png"
import image02Mo from "../../../images/certification/mobile_type02-07.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import { MoItems01 } from '../../../components/StyledComponents'

// styles
const Container = styled.div`
   & .main_img{
      max-width: 1020px;
   }
`

const Mainbiz = () => {

   const overViewInfo = {
      title: "메인비즈",
      desc: "Innovation(혁신)과 Business(기업)의 합성어로 기술 우위를 바탕으로 경쟁력을 확보한 기술혁신형 중소기업을 지칭합니다. 전 세계적으로 기술 혁신을 통해 기업과 국가의 경쟁력을 높이려는 뉴 패러다임이 새로운 화두로 떠오르고 있기에 미국, 독일 OECD 선진국들은 중소벤처기업을 국가경쟁력의 핵심으로 일찍이 95년부터 정부차원에서 전폭적인 지원 정책을 실시해 왔으며, 국가간의 경쟁력을 측정하는 객관적인 척도로 비교되고 있습니다.",
      logo: logo,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "중소벤처기업부 <br/>한국경영혁신중소기업협회",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "3년",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "약 3~4주 정도 소요",
            bgImg: icon11,
         },
         {
            title: "심사비용",
            desc: "신규 55만원 <br/>갱신 44만원",
            bgImg: icon08,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "중소기업기본법 제2조의 규정에 의한 중소기업 중 업력이 3년 이상인 기업",
                  "서비스, 마케팅, 공정, 인사조직 등 경영전반에 혁신 활동 및 역량있는 우수 중소 기업",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "불건전 영상게임기",
                  "도박게임장비 등 불건전, 오락용품 제조업",
                  "담배중개업",
                  "주류, 담배도매업",
                  "숙박업 및 주점업 (단, 관광진흥법에 의한 관광숙박업은 지원가능)",
                  "불건전 게임소프트웨어 개발 및 공급업",
                  "연체, 국세체납 등으로 인하여 신용관리정보대상자로 규제를 받고 있는 기업",
                  "직전 사업연도 말일 현재 재무상태표 기준 부채비율이 1,000% 이상인 기업",
                  "직전 사업연도 말일 현재 재무상태표 기준 완전자본잠식 상태에 있는 기업",
                  "파산, 회생절차개시, 개인회생절차 개시 신청이 있거나 청산에 들어간 기업",
               ]
            },
         ]
      },
   }
   const Benefits02Data = [
      {
         title: "※ 국세 · 관세",
         items: [
            "정기세무조사유예 및 세금포인트제도(국세청)",
            "보증료율 및 매출채권 보험 보험료율 인하 우대(신용보증기금)",
            "관세조사유예(관세청)",
         ],
      },
      {
         title: "※ 인력",
         items: [
            "공공연 연구인력 파견지원(한국산업기술진흥원)",
            "병역특례지원(중소벤처기업부)",
         ],
      },
      {
         title: "※ 판로수출",
         items: [
            "방송 광고비 감면(한국방송광고진흥공사)",
            "물품구매 및 일반용역 적격 심사 우대(조달청)",
            "공영쇼핑 우수제품 입점판매(중소벤처기업부)",
         ],
      },
      {
         title: "※ R&D",
         items: [
            "산학연 Collabo R&D 및 중소기업 R&D 역량제고(중소벤처기업부)",
            "포스트 규제자유특구 연계 R&D(중소벤처기업부)",
            "Tech-Bridge활용 상용화기술 개발사업(중소벤처기업부)",
            "건강기능식품 개발 지원사업(중소벤처기업부)",
            "글로벌 방산 강소기업 육성사업(방위사업청)",
         ],
      },
      {
         title: "※ 기타",
         items: [
            "취업포탈 사이트 홍보(인크루트/사람인)",
            "경영혁신역량 성장지원(SGI 서울보증 보험)",
            "기술자료 임치제도 수수료 감면(대중소기업 농어업협력재단) ",
         ],
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList info={CheckListData} />
         <section className='xl:pt-80 xl:pb-80 bg-subColor11'>
            <div className='container xl:flex justify-between'>
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div className='xl:block hidden'>
                  <img className='main_img xl:mt-24' src={image01} alt="심사 절차" />
               </div>
               <ul className="xl:hidden mo_items_wrap">
                  <MoItems01 bgColor="#75c5f5">연구소 (전담부서) 설립</MoItems01>
                  <MoItems01 bgColor="#48afec">설립신고 등록</MoItems01>
                  <MoItems01 bgColor="#2ea4e9">설립신고 접수 및 검토</MoItems01>
                  <MoItems01 bgColor="#1592dc">인증서 발급</MoItems01>
                  <MoItems01 bgColor="#1592dc">사후관리</MoItems01>
               </ul>
            </div>
         </section>
         <Benefits02 info={Benefits02Data} />
         <Consulting
            imgSrc={image02}
            moImgSrc={image02Mo}
         />
         <ContactBanner />
      </Container>
   )
}

export default Mainbiz;