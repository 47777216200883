import styled from 'styled-components'

// components
import { CertificationInfo, ContactBanner, Overview } from '../../../components/SubPageCommonUI'

// images
import logo from "../../../images/certification/logo16.png"
import image01 from "../../../images/certification/type01-17.png"
import table10 from "../../../images/certification/table10.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"
import { MoItems01 } from '../../../components/StyledComponents'

// styles
const Container = styled.div`
   & .main_img{
      max-width: 1020px;
   }
`
const CheckListWrap = styled.section`
   & .sub_title:first-of-type{
      margin-top: 0px;
   }
   & .item_wrap{
      width:100%;
      max-width: 1020px;
      margin-left: auto;
      & .item{
         width: 100%;
      }
   }
   @media screen and (max-width:1280px){
      .target_wrap{
         .main_img_wrap{
            .main_img{
               width: auto;
               height: 80px;
            }
         }
      }
   }
`
const BenefitsContainer = styled.section`
   .title{
      white-space: nowrap;
   }
   & .items_wrap{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      & .item{
         overflow: hidden;
         position: relative;
         width: 324px;
         height: 214px;
         border: 1px solid var(--subColor05);
         border-radius: 16px;
         & .num{
            position: absolute;
            bottom: -18px;
            right: 16px;
            color: rgba(93, 179, 230, 0.2);
         }
      }
   }
   @media screen and (max-width:1280px){
      .items_wrap{
         display: flex;
         flex-direction: column;
         gap: 16px;
         .item{
            width: 100%;
            height: 150px;
            padding: 30px;
            .num{
               right: 30px;
               font-size: 70px;
            }
         }
      }
   }
`

const GreenTechCert = () => {

   const overViewInfo = {
      title: "녹색기술인증",
      desc: "신산업, 미세먼지저감, 기후변화 관련기술 등의 인증을 통한 시장진출 지원으로 매출액 증가, 일자리 창출 등 산업육성 및 기업경쟁력 강화에 기여 하기위해 “ 탄소중립 기본법”에 의거하여 유망한 녹색기술을 인증 하고 지원하는 제도입니다. 녹색인증은 녹색기술 인증, 녹색기술제품 확인, 녹색전문기업 확인이 있습니다.",
      logo: logo,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "한국산업기술진흥원",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "발급날로부터 3년, 1회 연장 이후 재연장이 불가",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "약 1~2개월 이상",
            bgImg: icon11,
         },
         {
            title: "심사비용",
            desc: "녹색기술인증: 100만원 녹색기술제품확인: 30만원 녹색전문기업: 수수료없음",
            bgImg: icon08,
         },
      ],
   };

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckListWrap>
            <section className="target_wrap container xl:pt-80 xl:pb-100 pt-60 pb-60">
               <div className="xl:flex xl:gap-80">
                  <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">신청 가능 대상</h3>
                  <ul className="item_wrap">
                     <li className='sub_title xl:leading-1em xl:text-22 font-semibold xl:mb-24 xl:mt-48 mb-16 mt-40'>※ 녹색기술인증</li>
                     <li className="item flex gap-8 xl:pt-20 xl:pb-20 xl:pl-24 xl:pr-24 xl:mb-24 p-16 mb-16 bg-subColor12">
                        <i className="xi-check-circle-o mt-4 text-pointColor01"></i>
                        <p className='xl:leading-30 leading-24 xl:text-18 text-14'>에너지와 자원을 절약하고 효율적으로 사용하여 온실가스 및 오염물질의 배출을 최소화하는 기술</p>
                     </li>
                     <li className='main_img_wrap type_01'>
                        <img className='main_img' src={table10} alt="녹색기술인증" />
                     </li>
                     <li className='sub_title xl:leading-1em xl:text-22 font-semibold xl:mb-24 xl:mt-48 mb-16 mt-40'>※ 녹색기술제품확인</li>
                     <li className="item flex gap-8 xl:pt-20 xl:pb-20 xl:pl-24 xl:pr-24 xl:mb-24 p-16 mb-16 bg-subColor12">
                        <i className="xi-check-circle-o mt-4 text-pointColor01"></i>
                        <p className='xl:leading-30 leading-24 xl:text-18 text-14'>인증된 녹색기술을 적용한 제품으로 판매를 목적으로 상용화한 제품</p>
                     </li>
                     <li className="item flex gap-8 xl:pt-20 xl:pb-20 xl:pl-24 xl:pr-24 xl:mb-24 p-16 mb-16 bg-subColor12">
                        <i className="xi-check-circle-o mt-4 text-pointColor01"></i>
                        <p className='xl:leading-30 leading-24 xl:text-18 text-14'>녹색기술인증 확인, 제품생산가능여부, 품질 경영, 제품 성능을 모두 만족</p>
                     </li>
                     <li className='sub_title xl:leading-1em xl:text-22 font-semibold xl:mb-24 xl:mt-48 mb-16 mt-40'>※ 녹색전문기업확인</li>
                     <li className="item flex gap-8 xl:pt-20 xl:pb-20 xl:pl-24 xl:pr-24 xl:mb-24 p-16 mb-16 bg-subColor12">
                        <i className="xi-check-circle-o mt-4 text-pointColor01"></i>
                        <p className='xl:leading-30 leading-24 xl:text-18 text-14'>전년도 총 매출액에서 인증 받은 녹색기술에 의한 매출이 20% 이상인 기업</p>
                     </li>
                     <li className="item flex gap-8 xl:pt-20 xl:pb-20 xl:pl-24 xl:pr-24 xl:mb-24 p-16 mb-16 bg-subColor12">
                        <i className="xi-check-circle-o mt-4 text-pointColor01"></i>
                        <p className='xl:leading-30 leading-24 xl:text-18 text-14'>창업 후 1년이 경과한 기업</p>
                     </li>
                     <li className="item flex gap-8 xl:pt-20 xl:pb-20 xl:pl-24 xl:pr-24 xl:mb-24 p-16 mb-16 bg-subColor12">
                        <i className="xi-check-circle-o mt-4 text-pointColor01"></i>
                        <p className='xl:leading-30 leading-24 xl:text-18 text-14'>각 인증을 받은 녹색기술에 의한 신청 직전년도 매출액의 합이 신청 기업 총 매출액 20% 이상&#40;공인회계사 또는 세무사 확인&#41;</p>
                     </li>
                  </ul>
               </div>
            </section>
         </CheckListWrap>
         <section className='xl:pt-80 xl:pb-80 bg-subColor11'>
            <div className='container xl:flex justify-between'>
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <img className='xl:block hidden main_img' src={image01} alt="심사 절차" />
               <ul className="xl:hidden mo_items_wrap">
                  <MoItems01 bgColor="#75c5f5">인증(확인) 신청</MoItems01>
                  <MoItems01 bgColor="#48afec">인증(확인) 평가 의뢰</MoItems01>
                  <MoItems01 bgColor="#2ea4e9">적합성 확인</MoItems01>
                  <MoItems01 bgColor="#1592dc">현장평가, 종합평가</MoItems01>
                  <MoItems01 bgColor="#1592dc">결과 접수 및 인증 확정</MoItems01>
                  <MoItems01 bgColor="#1592dc">인증서 발급</MoItems01>
               </ul>
            </div>
         </section>
         <BenefitsContainer className='benefits xl:pt-80 pt-60'>
            <div className='container xl:flex xl:gap-150'>
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">인증혜택</h3>
               <div>
                  <div>
                     <p className='leading-1em xl:text-22 font-semibold'>※ 공공조달</p>
                     <ul className='items_wrap xl:gap-24 xl:mt-24 mt-14'>
                        <li className='item xl:p-32'>
                           <p>
                              물품구매 적격심사 및
                              다수공급자계약
                              &#40;조달청&#41;
                           </p>
                           <strong className='num leading-1em xl:text-80 font-bold'>01</strong>
                        </li>
                        <li className='item xl:p-32'>
                           <p>
                              우수조달 물품지정
                              &#40;조달청&#41;
                           </p>
                           <strong className='num leading-1em xl:text-80 font-bold'>02</strong>
                        </li>
                        <li className='item xl:p-32'>
                           <p>
                              최소 녹색기준
                              제품지정제도 &#40;조달청&#41;
                           </p>
                           <strong className='num leading-1em xl:text-80 font-bold'>03</strong>
                        </li>
                        <li className='item xl:p-32'>
                           <p>
                              기술개발 제품
                              우선/시범 구매제도
                              &#40;중소기업유통센터&#41;
                           </p>
                           <strong className='num leading-1em xl:text-80 font-bold'>04</strong>
                        </li>
                        <li className='item xl:p-32'>
                           <p>
                              혁신제품 지정제도
                              &#40;한국산업기술진흥원&#41;
                           </p>
                           <strong className='num leading-1em xl:text-80 font-bold'>05</strong>
                        </li>
                     </ul>
                  </div>
                  <div className='xl:mt-32 mt-40'>
                     <p className='leading-1em xl:text-22 font-semibold'>※ 사업화 지원</p>
                     <ul className='items_wrap xl:gap-24 xl:mt-24 mt-16'>
                        <li className='item xl:p-32'>
                           <p>
                              특허우선 심사제도
                              &#40;특허청&#41;
                           </p>
                           <strong className='num leading-1em xl:text-80 font-bold'>01</strong>
                        </li>
                        <li className='item xl:p-32'>
                           <p>
                              지식재산평가 지원사업
                              &#40;특허청&#41;
                           </p>
                           <strong className='num leading-1em xl:text-80 font-bold'>02</strong>
                        </li>
                     </ul>
                  </div>
                  <div className='xl:mt-32 mt-40'>
                     <p className='leading-1em xl:text-22 font-semibold'>※ 기타</p>
                     <ul className='items_wrap xl:gap-24 xl:mt-24 mt-16'>
                        <li className='item xl:p-32'>
                           <p>
                              해외전시회 개별/단체
                              지원사업
                              &#40;대한 무역투자 진흥공사&#41;
                           </p>
                           <strong className='num leading-1em xl:text-80 font-bold'>01</strong>
                        </li>
                        <li className='item xl:p-32'>
                           <p>
                              혁신형 중소기업 방송광고
                              활성화&#40;제작비&#41; 지원사업
                              &#40;한국 방송광고 진흥공사&#41;
                           </p>
                           <strong className='num leading-1em xl:text-80 font-bold'>02</strong>
                        </li>
                        <li className='item xl:p-32'>
                           <p>
                              혁신형 중소기업 방송광고비
                              할인 지원사업
                              &#40;한국 방송광고 진흥공사&#41;
                           </p>
                           <strong className='num leading-1em xl:text-80 font-bold'>03</strong>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </BenefitsContainer>
         <ContactBanner />
      </Container>
   )
}

export default GreenTechCert