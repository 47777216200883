import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo05 from "../../../images/certification/logo05.png"
import image01 from "../../../images/certification/type01-07.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon13 from "../../../images/common/icon/icon13.png"
import { MoItems01 } from "../../../components/StyledComponents"

// style
const Container = styled.div`
   & .table_wrap{
      & .table_img{
         width: 100%;
         border-bottom: 1px solid var(--subColor05);
      }
      & .list:not(:last-of-type){
         margin-bottom: 16px;
      }
   }
   & .examination{
      background-color: var(--subColor11);
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         height: auto;
      }
   }
`

const KMark = () => {

   const overViewInfo = {
      title: "K마크",
      desc: "공산품의 품질수준을 평가(시험, 검사)하여 인증하는 제도로서 기술개발 촉진, 품질향상과 소비자 선택의 편리성 및 부실 제작, 시공으로부터 <br class='xl:block hidden' />사용자 보호를 위한 3자적인 입장에서 객관적으로 평가 인증하는 제도입니다.",
      logo: logo05,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "한국산업기술평가원(KTL)",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "인증서 발급일로부터 2년 <br/>(1년마다 사후관리 필요)",
            bgImg: icon09,
         },
         {
            title: "접수기간",
            desc: "수시 접수",
            bgImg: icon13,
         },
         {
            title: "처리기간",
            desc: "약 3~4개월 소요(제품에 따라 상이)",
            bgImg: icon11,
         },
      ],
   };
   const BenefitsData = [
      {
         desc: "조달청 우수제품 선정 시, 품질소명자료(배점 최대 40점)",
      },
      {
         desc: "기술보증기금의 우선적보증지원<br />(기술인증획득기업)",
      },
      {
         desc: "정부의 행정정보 다기능사무기기<br />(노트북, 프린터 등) 적합성 요건 인증",
      }
   ]
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "※ 기계 및 화학제품",
               desc_text: [
                  "기계류: 공작기계, 산업기계, 공조기계, 승강기 및 부품류, 음식물쓰레기 감량화처리기 등",
                  "재료 및 화학 제품류: 세라믹, PE제품, 수지류 제품, 고무제품 등",
               ],
            },
            {
               desc_title: "※ 전기전자제품",
               desc_text: [
                  "전지전자 기기: 음향기기, 가전기기, 전기장치, 전기부품 류 등",
                  "측정 및 계측기기류: 환경계측기, 전기계측기, 정밀계측기 등",
                  "IT기기: 컴퓨터, 프린터, 프로젝터, 정보처리장치 등",
               ],
            },
            {
               desc_title: "※ 의료제품",
               desc_text: [
                  "의료기기: 심박수계, 체지방분석기, 효롸학분석기 등"
               ],
            },
            {
               desc_title: "※ 스포츠용품",
               desc_text: [
                  "러닝머신, 야외운동기구, 페러글라이더 등",
               ],
            },
            {
               desc_title: "※ 신개발품",
               desc_text: [
                  "일정한 품질인증기준이 없고 제품의 인지도를 높이려는 제품 및 부품",
               ],
            },
         ],
      },
      negative: {
         title: "인증 종류",
         icon:"xi-plus-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "제 1 종 : 사용자, 사용 목적, 제품의 특성 및 생산량을 고려하여 인증종류를 구분",
                  "제 2 종 : 제한된 지역에서 특정인에 의해 사용죄는 제품 또는 특정 목적에 사용하기 위한 주문생산 제품에 적용",
                  "제 3 종 : 제 1종 및 제 1종을 제외한 제품으로서 연구소에서 K마크 인증마크를 부여할 수 있다고 인정하는 제품에 적용",
               ]
            },
         ]
      },
   }

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="examination xl:pt-80 xl:pb-80">
            <div className="container">
            <h3 className="title leading-1em xl:text-32 text-24 font-bold xl:mb-0 mb-24">심사절차</h3>
               <img className="xl:block hidden main_img w-full xl:mt-48" src={image01} alt="심사절차" />
               <ul className="xl:hidden mo_items_wrap">
                  <MoItems01 bgColor="#75c5f5">상담 및 일반정보 제출</MoItems01>
                  <MoItems01 bgColor="#48afec">일반정보 및 인증규격 검토</MoItems01>
                  <MoItems01 bgColor="#2ea4e9">초기공장심사 시험 및 검사</MoItems01>
                  <MoItems01 bgColor="#1592dc">사후관리 협약체결</MoItems01>
                  <MoItems01 bgColor="#1592dc">인증서 발급</MoItems01>
                  <MoItems01 bgColor="#0e8dd7">사후관리</MoItems01>
               </ul>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default KMark;