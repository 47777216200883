import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo11 from "../../../images/certification/logo11.png"
import image01 from "../../../images/certification/type01-12.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import { MoItems01 } from "../../../components/StyledComponents"

// style
const Container = styled.div`
   & .examination{
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         max-width: 1020px;
         height: auto;
      }
   }
`

const HighEffEnergy = () => {

   const overViewInfo = {
      title: "고효율에너지",
      desc: "고효율에너지기자재인증제도는 에너지사용기자재 중 에너지효율 및 품질시험 검사 결과가 <br class='xl:block hidden'/>부가 고시한 일정기준 이상 만족하는 제품을 고효율에너지기자재로 인증하는 자발적 제도입니다.",
      logo: logo11,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "한국에너지공단",
            bgImg: icon07,
         },
         {
            title: "심사비용",
            desc: "기업규모에 따라 다름",
            bgImg: icon09,
         },
         {
            title: "유효기간",
            desc: "3년",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "신청 후 14일 이내<br/>(공휴일 및 공장심사일수 제외)",
            bgImg: icon11,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "조명설비 <br/>①LED유도등 ②문자간판용 LED모듈 ③등기구 ④LED램프 ⑤스마트LED조명",
                  "전력설비 <br/>①무정전전원장치 ②인버터 ③펌프 ④원심식 송풍기 ⑤터보압축기 ⑥전력저장장치(ESS) ⑦최대수요전력제어장치 ⑧전기자동차 충전장치",
                  "보일러 및 냉난방설비 <br/>①산업·건물용 가스보일러 ②스크류 냉동기 ③직화흡수식 냉온수기 ④항온항습기 ⑤가스히트펌프 ⑥가스진공온수보일러 ⑦중온수 흡수식 냉동기",
                  "단열설비 <br/>①고기밀성 단열문 ②냉방용 창유리필름 ③금속제 커튼월",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "스마트LED조명: 150W 초과도 포함하고 별도의 아날로그 조광기로만 제어되는 제품",
                  "스마트LED조명: 별도의 아날로그 조광기로만 제어되는 제품",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "제품 보급촉진을 위하여 공공기관 고효율에너지기자재 우선 구매",
      },
      {
         desc: "조달 구매 시 고효율인증제품 우선 구매",
      },
      {
         desc: "건축물의 에너지절약설계기준에 근거하여 고효율에너지기자재 의무 및 권장 사용",
      },
      {
         desc: "에너지이용합리화자금 융자지원제도",
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="examination xl:pt-80 xl:pb-80">
            <div className="container xl:flex justify-between">
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div>
                  <div>
                     <p className="leading-1em xl:text-24 text-14 font-semibold">※ 최초인증</p>
                     <img className="xl:block hidden main_img w-full xl:mt-24" src={image01} alt="심사절차" />
                     <ul className="xl:hidden mo_items_wrap mt-16">
                        <MoItems01 bgColor="#75c5f5">시험의뢰·시험성적서 발급</MoItems01>
                        <MoItems01 bgColor="#48afec">수수료 입금 및 신청서류 작성</MoItems01>
                        <MoItems01 bgColor="#2ea4e9">신청서류 검토</MoItems01>
                        <MoItems01 bgColor="#1592dc">공장심사 일정 조율</MoItems01>
                        <MoItems01 bgColor="#1592dc">공장심사 실시</MoItems01>
                        <MoItems01 bgColor="#1592dc">인증서 발급</MoItems01>
                     </ul>
                  </div>
                  <div className="mt-40">
                     <p className="xl:leading-36 leading-22 xl:text-24 text-14 font-semibold">※ 최초인증&#40;공장심사 면제&#41;: 유사품목 군에 대한 고효율에너지기자재 인증을 보유한 경우 공장심사 면제</p>
                     <img className="xl:block hidden main_img w-full xl:mt-24" src={image01} alt="심사절차" />
                     <ul className="xl:hidden mo_items_wrap mt-16">
                        <MoItems01 bgColor="#75c5f5">시험의뢰·시험성적서 발급</MoItems01>
                        <MoItems01 bgColor="#48afec">수수료 입금 및 신청서류 작성</MoItems01>
                        <MoItems01 bgColor="#2ea4e9">신청서류 검토</MoItems01>
                        <MoItems01 bgColor="#1592dc">인증서 발급</MoItems01>
                        <MoItems01 bgColor="#1592dc">인증서 확인 및 출력</MoItems01>
                     </ul>
                  </div>
                  <div className="mt-40">
                     <p className="xl:leading-36 leading-22 xl:text-24 text-14 font-semibold">※ 최초인증&#40;공장심사 면제_KS인증&#41; : 유사품목으로 KS인증 보유한 경우 공장심사 면제</p>
                     <img className="xl:block hidden main_img w-full xl:mt-24" src={image01} alt="심사절차" />
                     <ul className="xl:hidden mo_items_wrap mt-16">
                        <MoItems01 bgColor="#75c5f5">시험의뢰·시험성적서 발급</MoItems01>
                        <MoItems01 bgColor="#48afec">수수료 입금 및 신청서류 작성</MoItems01>
                        <MoItems01 bgColor="#2ea4e9">신청서류 검토</MoItems01>
                        <MoItems01 bgColor="#1592dc">인증서 발급</MoItems01>
                        <MoItems01 bgColor="#1592dc">인증서 확인 및 출력</MoItems01>
                     </ul>
                  </div>
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default HighEffEnergy;