// Components
import NoticeArea from "../../components/NoticeArea";

const Notice: React.FC<any> = () => {
   return (
      <div>
         <NoticeArea />
      </div>
   )
}

export default Notice;