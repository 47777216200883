import styled from "styled-components"

// components
import { Benefits, CertificationInfo, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo07 from "../../../images/certification/logo07.png"
import image01 from "../../../images/certification/type01-09.png"
import table07 from "../../../images/certification/table07.png"
import table08 from "../../../images/certification/table08.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon13 from "../../../images/common/icon/icon13.png"
import { MoItems01 } from "../../../components/StyledComponents"

// style
const Container = styled.div`
   & .examination{
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         max-width: 1020px;
         height: auto;
      }
   }
   @media screen and (max-width:1280px){
      .pos_target{
         .main_img_wrap{
            .main_img{
               width: auto;
               height: 160px;
            }
         }
      }
   }
`

const KcCert = () => {

   const overViewInfo = {
      title: "KC인증",
      desc: "동일 용도의 제품 중 제품의 전과정 각 단계에 걸쳐 에너지 및 자원의 소비를 줄이고 오염물질의 발생을 최소화할 수 있는 제품에 환경표지를 인증하는 국가 공인제도 기업과 소비자가 환경친화적인 제품을 생산 및 소비할 수 있도록 소비자에게 정확한 제품의 환경정보를 제공하여 환경보전활동에 참여토록 하고, 기업에게는 소비자의 친환경적 구매욕구에 부응하는 환경친화적인 제품과 기술을 개발하도록 유도하여 지속 가능한 생산과 소비생활을 이루는 것을 목적으로 합니다. ",
      logo: logo07,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "안전인증: 산자부지정 안전인증기관 <br/>전자파인증: 방통위 지정 시험기관",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "제품/인증별 상이",
            bgImg: icon09,
         },
         {
            title: "접수기간",
            desc: "상시 접수",
            bgImg: icon13,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "없음",
            bgImg: icon10,
         },
         {
            title: "심사비용",
            desc: "제품/인증별 상이",
            bgImg: icon08,
         },
      ],
   };
   const BenefitsData = [
      {
         desc: "국가공공기관이 품질을 보증함으로서 판매 촉진",
      },
      {
         desc: "지속적인 제품 및 품질관리(qc)의 기술 지도로 품질향상",
      },
      {
         desc: "배상물책임보험(pl보험)할인 및 검사 수수료 감면",
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <section className="pos_target bg-subColor11 xl:pt-80 xl:pb-80">
            <div className="container">
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">신청 가능 대상</h3>
               <div className="xl:mt-48">
                  <p className="leading-1em xl:text-24 text-14 font-semibold">※ 안전인증</p>
                  <div className="main_img_wrap type_01 xl:mt-0 mt-16">
                     <img className="main_img w-full xl:mt-24" src={table07} alt="심사비용" />
                  </div>
               </div>
               <div className="xl:mt-48 mt-40">
                  <p className="leading-1em xl:text-24 text-14 font-semibold">※ 전자파 인증</p>
                  <div className="main_img_wrap type_01 xl:mt-0 mt-16">
                     <img className="main_img w-full xl:mt-24" src={table08} alt="인증사용료 경감 조건" />
                  </div>
               </div>
            </div>
         </section>
         <section className="examination xl:pt-80 xl:pb-80 pt-60 pb-60">
            <div className="container xl:flex justify-between">
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div>
                  <p className="leading-1em xl:text-24 text-14 font-semibold">※ 안전인증 절차</p>
                  <img className="xl:block hidden main_img w-full xl:mt-24" src={image01} alt="심사절차" />
                  <ul className="xl:hidden mo_items_wrap mt-16">
                     <MoItems01 bgColor="#75c5f5">안전인증 신청서 제출</MoItems01>
                     <MoItems01 bgColor="#48afec">신청서 접수 및 검토</MoItems01>
                     <MoItems01 bgColor="#2ea4e9">공장심사 및 제품시험</MoItems01>
                     <MoItems01 bgColor="#1592dc">안전인증서 발급</MoItems01>
                     <MoItems01 bgColor="#1592dc">인증서수령 및 안정인증 표시</MoItems01>
                     <MoItems01 bgColor="#1592dc">현장 심사(서비스 인증)</MoItems01>
                  </ul>
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default KcCert;