
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import DOMPurify from "dompurify"

// conponents Start
import styled from "styled-components";
import PageControl from "../../components/PageControl"

// Props
import { DataProps } from "../../model/data";

// styled
const QuestionBox = styled.div`
   border-bottom: 2px solid var(--subColor05);
`
const Container = styled.div`
   border-top: 2px solid var(--subColor02);
   .icon_loading_wrap{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 500px;
   }
`

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

export default function Faq() {
   const query = useQuery();
   const page = query.get('page');

   const [data, setData] = useState<DataProps>();
   const [pageIndex, setPageIndex] = useState(page ? page : 1);

   const navigate = useNavigate();

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/question?page=${pageIndex}`);
            setData(response.data.data);
            if (response.data.data.state === false) {
               alert("일시적인 오류가 발생했습니다.");
            }
         } catch (error) {
            console.log(error);
         }
      };

      fetchData();
   }, [pageIndex]);

   useEffect(() => {
      navigate(`?page=${pageIndex}`);
   }, [pageIndex, navigate]);

   // List
   const [listActiveIndex, setListActiveIndex] = useState();
   const handelToggle = (index: any) => {
      setListActiveIndex(listActiveIndex === index ? null : index)
   }

   return (
      <Container className="contact_faq contact container">
         <div className="wrap">
            {data ? (
               data.total === 0 ? (
                  <div className="item noitem txt_align_center">게시글이 없습니다.</div>
               ) : (
                  data.data.map((data, index) => (
                     <div key={index} className="list_wrap">
                        <QuestionBox onClick={() => handelToggle(index)} className="list item question">
                           <p className="tit xl:text-32 text-18 font-bold">Q</p>
                           <p className="txt xl:leading-28 leading-24 xl:text-18 text-14 font-light">{data.title}</p>
                           <i className={`icon xi-angle-down-thin xl:text-32 text-18 pc ${listActiveIndex === index ? 'on' : ''}`}></i>
                        </QuestionBox>
                        <div className={`item item_answer ${listActiveIndex === index ? 'on' : ''}`}>
                           <p className="tit xl:text-32 text-18 font-bold">A</p>
                           <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.content) }} className="txt xl:leading-28 leading-24 xl:text-18 text-14 font-light"></p>
                        </div>
                     </div>
                  ))
               )
            ) : (
               <div className="icon_loading_wrap">
                  <i className="xi-spinner-2 xi-spin icon_loading"></i>
               </div>
            )}
            {data &&
               <PageControl
                  data={data}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  loading={''}
               />}
         </div>
      </Container>
   )
}