import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo03 from "../../../images/certification/logo03.png"
import image01 from "../../../images/certification/type03-02.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon11 from "../../../images/common/icon/icon11.png"
import table02 from "../../../images/certification/table02.png"
import table03 from "../../../images/certification/table03.png"

// style
const Container = styled.div`
   & .target_wrap{
      & .title{
         white-space: nowrap;
      }
      & .item_wrap{
         width: 100%;
      }
   }
   & .examination{
      background-color: var(--subColor11);
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         width: 100%;
         height: auto;
      }
   }
   & .benefits{
    background-color: var(--subColor11);
   }
   @media screen and (max-width:1280px){
      .day{
         .main_img_wrap{
            overflow-x: scroll;
            .main_img{
               width: auto;
               height: 200px;
            }
         }
      }
      .procedure{
         .main_img{
            width: auto;
            height: 550px;
         }
      }
   }
`

const QualityGuarantee = () => {

   const overViewInfo = {
      title: "품질보증조달물품인증",
      desc: "조달업체의 품질경영·공정관리·성과관리 등 품질관리 능력을 평가하여 우수한 품질보증 체계 하에 생산된 제품을 <br class='xl:block hidden' />‘품질보증조달물품’으로 지정하고 그 유효기간 동안 납품검사가 면제되는 물품을 말합니다.",
      logo: logo03,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "조달청",
            bgImg: icon07,
         },
         {
            title: "유효기간",
            desc: "등급에 따라 상이",
            bgImg: icon09,
         },
         {
            title: "처리기간",
            desc: "약 2개월",
            bgImg: icon11,
         },
         {
            title: "심사비용",
            desc: "등급에 따라 상이",
            bgImg: icon08,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "※ 조달청 경쟁입찰참가자격 등록증에 제조로 등록된 중견·중소 기업인으로서 다음 요건 중 하나에 해당하는 자",
               desc_text: [
                  "신청 세부품명에 대하여 최근 1년 이내 「물품구매계약 품질관리 특수 조건」 제 8조 및 제 9조에 따른 조달청검사 또는 전문기관 검사 실적이 있는 자",
                  "신청 세부품명이 국가종합전자조달시스템의 종합쇼핑몽에 등재되어 있는 자",
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "※ 모든 품명에 대한 신청을 허용하되, 다음 요건 중 하나에 해당하는 경우 심사대상에서 제외",
               desc_text: [
                  "제품의 이동·설치·시공 과정에서 제품의 성질·상태 등에 변형 가능성이 있어 품질 관리 확보가 곤란한 경우",
                  "조달물자로 공급하기 곤란한 음·식료품류, 동·식물류, 농·수산물류, 무기·총포·화약류와 그 구성품, 유류 및 의약품(농약) 등",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "납품검사면제<br/>(S등급 5년, A등급 4년, B등급 3년, 예비물품 1년)",
      },
      {
         desc: "우수조달물품 심사 시 기술품질가점 부여 및 품질소명자료로 인정",
      },
      {
         desc: "물품 다수공급자계약 2단계 경쟁 시 신인도 기술인증 가점 0.5점 부여",
      },
      {
         desc: "조달청 물품구매 적격심사 및 계약이행 능력심사 세부기준 신인도 0.75점 부여",
      },
      {
         desc: "물품 다수공급자 계약 시 제품 시험성적서 제출 면제 가능",
      },
      {
         desc: "조달청 나라장터 종합쇼핑몰에 등재된 물품에 대해서 전용몰 운영 및 마크 부여",
      }
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="xl:pt-80 xl:pb-80 pt-60 pb-60">
            <div className="day container">
               <h3 className="leading-1em xl:text-32 text-24 font-bold xl:mb-56 mb-24">신청 기간</h3>
               <div className="main_img_wrap type_01">
                  <img className="main_img w-full" src={table02} alt="신청 기간" />
               </div>
            </div>
         </section>
         <section className="day xl:pt-80 xl:pb-80 pt-60 pb-60 bg-subColor11">
            <div className="container">
               <h3 className="leading-1em xl:text-32 text-24 font-bold xl:mb-56 mb-24">지정 기간</h3>
               <div className="main_img_wrap type_01">
                  <img className="main_img w-full" src={table03} alt="지정 기간" />
               </div>
            </div>
         </section>
         <section className="procedure xl:pt-80 xl:pb-80 pt-60 pb-60">
            <div className="container">
               <h3 className="leading-1em xl:text-32 text-24 font-bold xl:mb-56 mb-24">심사절차</h3>
               <div className="main_img_wrap type_01">
                  <img className="main_img w-full" src={image01} alt="심사절차" />
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default QualityGuarantee;