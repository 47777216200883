import styled from 'styled-components';

// Props
interface PageControlProps {
   // data: DataProps;
   data: any;
   pageIndex: any;
   setPageIndex: any;
   loading: any;
}

// styles
const PaginationWrap = styled.ul`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   border-top: 2px solid var(--sub_color01);
`
const BtnPage = styled.button`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 40px;
   height: 40px;
   color: var(--subColor04);
   &.on{
      box-shadow: 0px 3px 0px 0px var(--pointColor01); 
      color: var(--subColor01);
   }
`

export default function PageControl({ data, pageIndex, setPageIndex, loading }: PageControlProps) {

   const prevClick = () => {
      data.current_page > 1 && setPageIndex(data.current_page - 1);
   }
   const nextClick = () => {
      data.current_page < data.last_page && setPageIndex(data.current_page + 1);
   }
   const handleClick = (label: string) => {
      setPageIndex(label)
   }

   return (
      <PaginationWrap className="xl:mt-40 mt-30 xl:mb-110">
         {loading ? (
            <div className="h-105"></div>
         ) : (
            data.links.map((links: any, index: number) => (
               links.label === "&laquo; Previous" ? (
                  <li key={index} data-index={index}>
                     <BtnPage onClick={() => { prevClick() }} className="control_btn">
                        <i className="xi-angle-left-min xl:text-20 text-14"></i>
                     </BtnPage>
                  </li>
               ) : links.label === "Next &raquo;" ? (
                  <li key={index} data-index={index}>
                     <BtnPage onClick={() => { nextClick() }} className="control_btn">
                        <i className="xi-angle-right-min xl:text-20 text-14"></i>
                     </BtnPage>
                  </li>
               ) : (
                  links.label === "..." ? (
                     <li key={index}>
                        <BtnPage style={{cursor: "not-allowed"}}>
                           {links.label}
                        </BtnPage>
                     </li>
                  ) : (
                     <li key={index}>
                        <BtnPage
                           onClick={() => { handleClick(links.label) }}
                           className={`control_btn pagination xl:text-16 text-14 ${parseInt(pageIndex) === parseInt(links.label) ? "on" : ""}`}
                        >
                           {links.label}
                        </BtnPage>
                     </li >
                  )
               )
            ))
         )
         }
      </PaginationWrap >
   )
}