import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, Consulting, ContactBanner, Distinction, Overview } from "../../../components/SubPageCommonUI"

// images
import image02 from "../../../images/certification/type01-02.png"
import image03 from "../../../images/certification/type01-03.png"
import image04 from "../../../images/certification/type02-02.png"
import image04Mo from "../../../images/certification/mobile_type02-02.png"
import logo from "../../../images/certification/logo02.png"
import icon07 from "../../../images/common/icon/icon07.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon10 from "../../../images/common/icon/icon10.png"
import icon11 from "../../../images/common/icon/icon11.png"

// Props
import { MoItems01Props } from "../../../model/company/Procedure"

// style
const Container = styled.div`
   & .target_wrap{
      & .title{
         white-space: nowrap;
      }
      & .item_wrap{
         width: 100%;
      }
   }
   & .examination{
      background-color: var(--subColor11);
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         width: 100%;
         height: auto;
      }
   }
`
const MoItems01 = styled.li<MoItems01Props>`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 40px;
   font-size: 14px;
   color: #fff;
   background-color: ${(props) => props.bgColor};
   &:not(:last-of-type){
      margin-bottom: 8px;
   }
`

const Innoproduct = () => {

   const overViewInfo = {
      title: "혁신시제품인증",
      desc: "조달청 공고 지정분야로 지정신청한 상용화 직전 제품(또는 ‘제품+서비스’)을 혁신시제품이라고 합니다. <br class='xl:block hidden' />평가와 조달정책심의위원회 심의를 통과할 경우 정부 혁신시제품으로 지정합니다.",
      logo: logo,
   }
   const CertificationData = {
      contents: [
         {
            title: "인증기관",
            desc: "조달청/혁신장터",
            bgImg: icon07,
         },
         {
            title: "심사비용",
            desc: "없음",
            bgImg: icon08,
         },
         {
            title: "유효기간",
            desc: "3년",
            bgImg: icon09,
         },
         {
            title: "최대 신청 가능 횟수",
            desc: "동일한 식별번호로 4회 ",
            bgImg: icon10,
         },
         {
            title: "처리기간",
            desc: "약 4개월",
            bgImg: icon11,
         },
      ],
      cont_desc: "※ 특허·실용신안 만료일자나 규제샌드박스 심의 기간이 지정일로부터 3년 이내일 경우 해당 지정일까지를 지정기간으로 함",
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "제안제품에 적용된 기술의 권리를 보유하고 제조납품이 가능한 기업",
                  `
                  개발완성수준(TRL)이 7,8,9단계인 경우<br/><br/>
                  7단계: 현장 적용 및 활용이 가능한 시제품 개발 완료 예) 실험실 테스트 多, 시험성적서(자체, 공인) 有<br/>
                  8단계: 현장 요구사항이 반영되어 양산을 위한 검증 완료 예) 현장적용 사례 有, 실증완료 보고서 有<br/>
                  9단계:양산을 위한 모든 요건이 완료(시장출시 직전 단계) 예) 양산 준비 완료, 법정 인증 완료 등
                  `,
               ],
            },
         ],
      },
      negative: {
         title: "신청 불가 대상",
         icon: "xi-close-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "신청 제품의 물품식별번호가 ‘기지정 혁신(시)제품, 우수조달물품, 우수공동상표 물품, 다수공급계약(MAS) 물품’의 물품식별번호와 동일한 경우(자사만 해당)",
                  "동일한 물품식별번호로 혁신시제품 평가에서 탈락한 횟수가 4회에 달하는 경우",
                  "'중소기업제품 구매촉진 및 판로지원에 관한 법률'에 따른 '중소기업자간 경쟁물품'에 대해서는 대기업.중견기업이 제안 불가",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "3년 간 국가 또는 <br/>지방자치단체와 수의계약 가능",
      },
      {
         desc: "조달청 예산으로 구매<br/>(지정기간 동안 1회에 한해 진행 가능)",
      },
      {
         desc: "구매 실적을 기관평가에 반영<br/>(기관별 총 물품 구매액의 1.0%~2.0%)",
      },
      {
         desc: "시범구매 테스트에서 성공 판정을 받은 경우 우수제품 신청자격 부여",
      }
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <Distinction />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="examination xl:pt-80 xl:pb-80 pt-60 pb-60">
            <div className="container xl:flex justify-between xl:gap-150">
               <h3 className="title leading-1em xl:text-32 font-bold">심사절차</h3>
               <div>
                  <p className="xl:text-24 text-16 font-semibold xl:mt-0 xl:mb-24 mt-24 mb-16">*공급자 제안형</p>
                  <img className="xl:block hidden main_img xl:mb-48" src={image02} alt="심사절차" />
                  <ul className="xl:hidden mo_items_wrap">
                     <MoItems01 bgColor="#75c5f5">제안 접수</MoItems01>
                     <MoItems01 bgColor="#48afec">서류 검토</MoItems01>
                     <MoItems01 bgColor="#2ea4e9">혁신시제품 평가</MoItems01>
                     <MoItems01 bgColor="#1592dc">2차 서류 제출</MoItems01>
                     <MoItems01 bgColor="#1592dc">혁신시제품 지정</MoItems01>
                  </ul>
                  <p className="xl:text-24 font-semibold xl:mt-0 xl:mb-24  mt-40 mb-16">*수요자 제안형</p>
                  <img className="xl:block hidden main_img" src={image03} alt="심사절차" />
                  <ul className="xl:hidden mo_items_wrap">
                     <MoItems01 bgColor="#75c5f5">도전적 과제 선정</MoItems01>
                     <MoItems01 bgColor="#48afec">제안 접수</MoItems01>
                     <MoItems01 bgColor="#2ea4e9">서류 검토</MoItems01>
                     <MoItems01 bgColor="#1592dc">시제품 평가</MoItems01>
                     <MoItems01 bgColor="#1592dc">2차 서류 제출, 현장실태조사</MoItems01>
                     <MoItems01 bgColor="#0e8dd7">혁신시제품 지정</MoItems01>
                  </ul>
                  <div className="mt-24">
                     <p className="xl:leading-32 leading-24 xl:text-16 text-12 text-subColor03">
                        * 공급자/수요자 제안형 공통된 합격 기준<br />
                        1&#41; 공공성 평가 : 세부항목 모두 적합으로 평가될 경우 통과<br />
                        2&#41; 공공성 평가를 통과한 업체에 한하여 실시<br />
                        -&#62; 세부항목 합산 점수가 70점 이상인 경우 혁신성 평가 통과
                     </p>
                  </div>
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <Consulting 
         imgSrc={image04}
         moImgSrc={image04Mo}
          />
         <ContactBanner />
      </Container >
   )
}

export default Innoproduct;