import styled from "styled-components"

// components
import { Benefits, CertificationInfo, CheckList, ContactBanner, Overview } from "../../../components/SubPageCommonUI"

// images
import logo13 from "../../../images/certification/logo13.png"
import image01 from "../../../images/certification/type01-14.png"
import icon08 from "../../../images/common/icon/icon08.png"
import icon11 from "../../../images/common/icon/icon11.png"
import icon09 from "../../../images/common/icon/icon09.png"
import icon13 from "../../../images/common/icon/icon13.png"
import { MoItems01 } from "../../../components/StyledComponents"

// style
const Container = styled.div`
   & .examination{
      & .title{
         white-space: nowrap;
      }
      & .main_img{
         max-width: 1020px;
         height: auto;
      }
   }
`

const QMark = () => {

   const overViewInfo = {
      title: "Q마크인증",
      desc: "품질인증(Q-Mark)제도는「국가표준기본법」제30조3 제①항에 의거 전기, 전자, 기계, 화학, 토목,건축자재분야 등 공산품의 품질향상 및소비자의 권익 보호를 위하여 우수한 품질의 제품을 제조·판매하는 제조업체, 유통판매업체를 대상으로 제품의 품질을 인증하는 제도입니다. 전기전자제품 및 기타 공산품의 품질을 보증하고 성능평가 및 안전성 평가를 거쳐 합격된 제품은 Q마크를 부착할 수 있는 제3자 인증 제도입니다.",
      logo: logo13,
   }
   const CertificationData = {
      contents: [
         {
            title: "유효기간",
            desc: "1년",
            bgImg: icon09,
         },
         {
            title: "접수기간",
            desc: "상시 접수",
            bgImg: icon13,
         },
         {
            title: "처리기간",
            desc: "1~2개월 소요",
            bgImg: icon11,
         },
         {
            title: "심사비용",
            desc: "인증기관마다 심사비용 상이",
            bgImg: icon08,
         },
      ],
   };
   const CheckListData = {
      positive: {
         title: "신청 가능 대상",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "인증기관에서 표준과 기준이 제정되어 있는 제품을 생산하는 제조업체를 대상으로 함.(KS기준이 없고, 품질인증이 필요한 제품)",
               ],
            },
         ],
      },
      negative: {
         title: "인증 기관",
         icon: "xi-plus-circle",
         desc: [
            {
               desc_title: "",
               desc_text: [
                  "한국기계전기전자시험연구원",
                  "한국건설생활환경시험연구원",
                  "한국화학융합시험연구원",
                  "한국의류시험연구원",
                  "FITI시험연구원",
               ]
            },
         ]
      },
   }
   const BenefitsData = [
      {
         desc: "조달우수제품 선정 가점",
      },
      {
         desc: "공공기관 입찰 가점수혜",
      },
      {
         desc: "기술보증 우대 및 혜택",
      },
      {
         desc: "책임배상보험 할인 혜택",
      },
      {
         desc: "정부 적합성 요건 인증",
      },
   ]

   return (
      <Container>
         <Overview
            info={overViewInfo}
         />
         <CertificationInfo
            info={CertificationData}
         />
         <CheckList
            info={CheckListData}
         />
         <section className="examination xl:pt-80 xl:pb-80">
            <div className="container xl:flex justify-between">
               <h3 className="whitespace-nowrap leading-1em xl:text-32 text-24 font-bold xl:mb-48 mb-24">심사절차</h3>
               <div>
                  <div className="xl:block hidden">
                     <img className="main_img w-full xl:mt-24" src={image01} alt="심사절차" />
                  </div>
                  <ul className="xl:hidden mo_items_wrap">
                     <MoItems01 bgColor="#75c5f5">신청서 접수</MoItems01>
                     <MoItems01 bgColor="#48afec">공장심사 일정 통보</MoItems01>
                     <MoItems01 bgColor="#2ea4e9">공장심사 및 제품심사</MoItems01>
                     <MoItems01 bgColor="#1592dc">제품 시험 및 결과 검토</MoItems01>
                     <MoItems01 bgColor="#1592dc">품질보증 부회 소집</MoItems01>
                     <MoItems01 bgColor="#1592dc">품질보증약정 및 지정</MoItems01>
                  </ul>
               </div>
            </div>
         </section>
         <Benefits
            info={BenefitsData}
         />
         <ContactBanner />
      </Container >
   )
}

export default QMark;