import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";

// conponents
import PageControl from "./PageControl";

// Props
import { DataProps } from "../model/data"

// Images
import arrowIcon from "../images/common/icon/arrow-bottom.png"

// styled 
const Container = styled.div`
   #par_page_select{
      appearance: none; /* 기본 스타일 제거 */
      -webkit-appearance: none; /* Safari에서 기본 스타일 제거 */
      -moz-appearance: none; /* Firefox에서 기본 스타일 제거 */
      height: 50px;
      border: 2px solid var(--subColor05);
      padding: 0px 28px 0px 16px;
      background-image: url(${arrowIcon});
      background-size: 12px auto;
      background-position: right 12px center;
   }
   @media screen and (max-width:1280px){
      .notice{
         padding: 8px 16px;
      }
      .item {
         .a_btn{
            padding: 16px 0px;
         }
      }
   }
`
const ListWrap = styled.ul`
   display: flex;
   flex-direction: column;
   width: 100%;
   border-bottom: 2px solid var(--subColor01);
   & .a_btn {
      align-items: center;
      border-bottom: 1px solid #e4e4e4;
      border-bottom: 1px solid var(--subColor05);
      display: flex;
      gap: 34px;
      padding: 24px 20px;
   }

   & .a_btn:hover,
   & .a_btn:focus {
      background-color: #f5faff;
   }

   & .a_btn .notice {
      width: fit-content;
      border: 1px solid var(--pointColor01);
      border-radius: 9999px;
      padding: 8px 16px;
      color: var(--pointColor01);
   }

   & .a_btn .date {
      color: var(--subColor04);
   }
`

const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

const NoticeArea: React.FC = () => {

   const navigate = useNavigate();

   // data
   const pathname = useLocation().pathname
   const [data, setData] = useState<DataProps | null>();
   const [loading, setLoading] = useState(true);

   const query = useQuery();
   const page = query.get('page');
   const search = query.get('search');

   const [pageIndex, setPageIndex] = useState(page ? page : 1);
   const [searchTermWrap, setSearchTermWrap] = useState(search ? search : '');
   const [searchTerm, setSearchTerm] = useState(searchTermWrap ? searchTermWrap : '');
   const [perPageValue, setPerPageValue] = useState(10);

   const perPageChange = (event: any) => {
      setPerPageValue(parseInt(event.target.value, 10));
      setPageIndex(1)
   };

   const handleChange = (event: any) => {
      setSearchTermWrap(event.target.value);
   };

   const handleSubmit = (event: any) => {
      event.preventDefault();

      setPageIndex(1)
      setSearchTerm(searchTermWrap);
      navigate(`?page=${pageIndex}&search=${searchTerm}`);
   };

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/${target()}?page=${pageIndex}&search=${searchTerm}&per_page=${perPageValue}`);
            setData(response.data.data);
            if (response.data.success === false) {
               alert(response.data.message)
            }
         } catch (error) {
            console.log(error);
         } finally {
            setLoading(false);
         }
      };

      const target = () => {
         if (pathname === "/customer/notice") {
            return "announcement"
         } else if (pathname === "/customer/information") {
            return "share"
         }
      }

      fetchData();
      navigate(`?page=${pageIndex}&search=${searchTerm}`);
   }, [searchTerm, pageIndex, navigate, pathname, perPageValue]);

   return (
      <Container className="type_notice">
         <div className="top_nav type_01 container xl:mt-80">
            <p className="text_count xl:text-18 xl:mt-0 mt-40">총 {data && <strong className="point">{data.total}</strong>}개의 게시물</p>
            <div className="search_item_wrap xl:flex xl:gap-16">
               <select
                  value={perPageValue} onChange={perPageChange}
                  name="per_page"
                  id="par_page_select"
               >
                  <option value="10">1페이지에 10개까지</option>
                  <option value="20">1페이지에 20개까지</option>
                  <option value="30">1페이지에 30개까지</option>
               </select>
               <form className="search_from" onSubmit={handleSubmit}>
                  <fieldset>
                     <legend className="hide">검색</legend>
                     <div className="search_box">
                        <input
                           id="search"
                           type="search"
                           name="search"
                           placeholder="검색어를 입력하세요"
                           value={searchTermWrap}
                           onChange={handleChange}
                        />
                        <button onClick={handleSubmit} type="button">
                           <i className="xi-search icon_search" />
                        </button>
                     </div>
                  </fieldset>
               </form>
            </div>
         </div>
         <ListWrap className="container list_wrap xl:mb-40">
            {data ? (
               data.total === 0 ? (
                  <li className="item noitem txt_align_center">게시글이 없습니다.</li>
               ) : (
                  (() => {
                     const featuredItems: any = [];
                     const nonFeaturedItems: any = [];

                     data.data.forEach((list, index) => {
                        const item = (
                           <li key={index} className="item">
                              <Link to={`./${list.id}?page=${pageIndex}&search=${searchTerm}`} className="a_btn">
                                 <div>
                                    {list.is_featured === "true" && <p className="notice leading-1em xl:text-16 text-12 xl:mb-12 mb-8 font-bold">공지</p>}
                                    <p className="tit xl:text-18 text-14 font-bold xl:mb-20 mb-16">{list.title}</p>
                                    <p className="date xl:text-16 text-12">{list.created_at_formatted}</p>
                                 </div>
                                 <div className="icon_wrap type_01 pc">
                                    <i className="icon xi-angle-right"></i>
                                 </div>
                              </Link>
                           </li>
                        );

                        if (list.is_featured === "true") {
                           featuredItems.push(item);
                        } else {
                           nonFeaturedItems.push(item);
                        }
                     });

                     return [...featuredItems, ...nonFeaturedItems];
                  })()
               )
            ) : (
               <li className="item">
                  <div className="icon_loading_wrap">
                     <i className="xi-spinner-2 xi-spin icon_loading"></i>
                  </div>
               </li>
            )}
         </ListWrap>

         {data &&
            <PageControl
               data={data}
               pageIndex={pageIndex}
               setPageIndex={setPageIndex}
               loading={loading}
            />}
      </Container>
   )
}

export default NoticeArea;