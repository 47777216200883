import styled from "styled-components"

// Components
import FooterNoMg from "../../components/FooterNoMg"

// images
import icon14 from "../../images/common/icon/icon14.png"
import image01 from "../../images/service-info/service-necessity.png"
import image01Mo from "../../images/service-info/mobile-service-necessity.png"

const Container = styled.div`
   & .overview{
      border-bottom: 1px solid var(--subColor05);
   }
   & .service_info{
      & .items_wrap{
         width: 100%;
         max-width: 1020px;
         & .item:first-of-type{
            border-top: 1px solid var(--subColor07);
         }
         & .item{
            border-bottom: 1px solid var(--subColor07);
         }
         & .icon_wrap{
            width: 62px;
            height: 62px;
            & .icon{
               width: 34px;
               height: auto;
            }
         }
      }
   }
   @media screen and (max-width:1280px){
      .service_info {
         .items_wrap {
            .icon_wrap{
               width: 26px;
               min-width: 26px;
               height: 26px;
               .icon{
                  width: 100%;
                  height: auto;
               }
            }
         }
      }
   }
`

export default function ServiceInfo() {

   FooterNoMg();

   return (
      <Container>
         <div className="overview container xl:pt-80 xl:pb-80 pt-60 pb-60">
            <p className="text-center xl:leading-30 xl:text-20 text-14 xl:mt-24 mt-16">차별화된 기술개발로 특허&#40;우수조달인증이 가능한 특허&#41;가 등록되고 인증까지 원스톱으로 서비스 받음으로써 시간, 비용, 노력이 획기적으로 절감되는 서비스입니다.</p>
         </div>
         <div className="service_info container xl:flex justify-between xl:pt-80 xl:pb-80 pt-60 pb-60">
            <h3 className="leading-1em xl:text-32 text-24 font-bold xl:mb-0 mb-24">서비스 내용</h3>
            <ul className="items_wrap">
               <li className="item flex xl:items-center gap-16 xl:pt-20 xl:pb-20 pt-24 pb-24">
                  <div className="icon_wrap flex justify-center items-center">
                     <img className="icon" src={icon14} alt="" />
                  </div>
                  <div>
                     <h4 className="xl:leading-32 leading-26 xl:text-24 text-16 font-bold">
                        <strong className="text-pointColor01">기술 및 특허 진단</strong>으로<br className="xl:hidden" /> 우수조달제품인증 가능성 향상
                     </h4>
                     <p className="xl:leading-28 leading-24 xl:text-18 text-14 xl:mt-8 mt-16">
                        저희의 사전 진단 서비스는 특허기술이 제품 성능 향상에 어떻게 기여하는지 명확히 보여줍니다.
                        이를 통해 우수조달제품인증을 획득하는 가능성을 극대화하세요.
                     </p>
                  </div>
               </li>
               <li className="item flex items-center gap-16 xl:pt-20 xl:pb-20 pt-24 pb-24">
                  <div className="icon_wrap flex justify-center items-center">
                     <img className="icon" src={icon14} alt="" />
                  </div>
                  <div>
                     <h4 className="xl:leading-32 leading-26 xl:text-24 text-16 font-bold">
                        <strong className="text-pointColor01">제품의 우수성을 파악</strong>하는 기술 및 특허 진단
                     </h4>
                     <p className="xl:leading-28 leading-24 xl:text-18 text-14 xl:mt-8 mt-16">
                        진단서비스는 제품에 적용된 특허기술의 성능을 철저히 평가하며,
                        우수조달제품인증 신청 전단계에서 성능인증을 획득할 가능성을 높입니다.
                     </p>
                  </div>
               </li>
               <li className="item flex items-center gap-16 xl:pt-20 xl:pb-20 pt-24 pb-24">
                  <div className="icon_wrap flex justify-center items-center">
                     <img className="icon" src={icon14} alt="" />
                  </div>
                  <div>
                     <h4 className="xl:leading-32 leading-26 xl:text-24 text-16 font-bold">
                        <strong className="text-pointColor01">전문 인력</strong>을 통한 진단 서비스 제공
                     </h4>
                     <p className="xl:leading-28 leading-24 xl:text-18 text-14 xl:mt-8 mt-16">
                        저희 전문가들은 특허청 선행기술조사, 특허맵 작성, 분석, 그리고 기술동향 조사를 통해 다양한 분야에서
                        성공적인 프로젝트를 수행한 경력을 보유하고 있습니다.
                     </p>
                  </div>
               </li>
            </ul>
         </div>
         <div className="service_necessity xl:pt-80 xl:pb-80 pt-60 pb-60 bg-subColor11">
            <div className="container">
               <h3 className="xl:text-center leading-1em xl:text-32 text-24 font-bold xl:mb-0 mb-24">서비스 필요성</h3>
               <img className="xl:block hidden w-full xl:mt-48" src={image01} alt="서비스 필요성" />
               <img className="xl:hidden w-full xl:mt-48" src={image01Mo} alt="서비스 필요성" />
            </div>
         </div>
      </Container>
   )
}