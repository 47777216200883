import styled from "styled-components"

// type
import { SubPageTab } from "../../model/subPageTabArea"

// components
import SubPageTabArea from "../../components/SubPageTabArea"

// images
import circle01 from "../../images/common/circle01.png"
import { useEffect, useState } from "react"

// style
const Container = styled.div`
   & .ceo_text_wrap{
      background-color: var(--subColor10);
      & .point{
         color: var(--pointColor01);
      }
      & .ceo_profile_wrap{
         & .item{
            position: relative;
            color: var(--subColor03);
         }
         & .item::after{
            content: '';
            display: block;
            clear: both;
            position: absolute;
            top: 6px;
            left: 0px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--subColor01);
         }
         & .item:not(:last-of-type){
            margin-bottom: 14px;
         }
      }
      & .ceo_img_wrap{
         width: 405px;
         height: 490px;
         background-color: var(--pointColor02);
      }
   }
   & .history{
      & .tab_btn_wrap{
         width: fit-content;
         margin-left: auto;
         margin-right: auto;
         & .btn{
            width: 280px;
            border-bottom: 2px solid var(--subColor04);
         }
         & .btn.on{
            border-bottom: 2px solid var(--pointColor01);
            color: var(--pointColor01);
         }
      }
      & .year_items{
         & .history_img_wrap{
            width: 100%;
            max-width: 600px;
            & .history_img{
            width: 100%;
            max-width: 385px;
            margin-left: auto;
            }
         }
         & .line{
            position: relative;
            order: 1;
            width: 1px;
            height: auto;
            background-color: var(--subColor03);
         }
         & .line::after{
            content: '';
            display: block;
            clear: both;
            position: absolute;
            top: 0px;
            left: 0px;
            transform: translateX(-50%);
            width: 20px;
            height: 20px;
            background-image: url(${circle01});
            background-size: cover;
         }
         & .text_wrap{
            width: 100%;
            max-width: 600px;
            margin-top: -11px;
            & .desc:not(:last-of-type){
               margin-bottom: 8px;
            }
         }
         &:last-of-type .line{
            background-color: transparent;
         }
         &:last-of-type .history_img_wrap,
         &:last-of-type .text_wrap{
            margin-bottom: 0px;
         }
         &:nth-of-type(2n-1){
            & .history_img_wrap{
               order: 0;
            }
            & .text_wrap{
               order: 2;
            }
         }
         &:nth-of-type(2n){
            & .history_img_wrap{
               order: 2;
               & .history_img{
                  margin-right: auto;
                  margin-left: 0px;
               }
            }
            & .text_wrap{
               order: 0;
               & *{

                  text-align: right;
               }
            }
         }
      }
   }
   /*  */
   & .map_wrap{
      position: relative;
      & .map {
         width: 100%;
         height: 590px;
         background-color: var(--subColor_05);
      }
      & .map #iframe_map {
         width: 100%;
         height: 100%;
      }
      & .contact_info_wrap {
         z-index: 9;
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         display: flex;
         justify-content: flex-end;
         width: 100%;
      }
      & .contact_info {
         position: absolute;
         top: 50%;
         right: 80px;
         transform: translateY(-50%);
         width: 470px;
         padding: 32px 38px;
         background: rgb(230, 120, 57);
         background: var(--pointColor01);
      }
      & .contact_info * {
         color: #fff;
      }
      & .contact_info .tit::after {
         content: '';
         display: block;
         clear: both;
         width: 34px;
         height: 5px;
         margin: 14px 0px 14px 0px;
         background-color: #fff;
      }
      & .contact_info .list_item {
         display: flex;
         align-items: center;
         gap: 10px;
      }
      & .contact_info .list_item:not(:last-of-type) {
         margin-bottom: 10px;
      }
      & .contact_info .icon {
         font-size: 21px;
      }
   }
   @media screen and (max-width:1280px){
      .map_wrap{
         .map{
            height: 600px;
         }
         .contact_info_wrap{
            top: auto;
            bottom: 0px;
            left: 0px;
            right: auto;
            transform: translate(0px);
            padding: 0px 16px;
            .contact_info{
               position: static;
               transform: translate(0px);
            }
         }
      }
   }
   @media screen and (max-width:1280px){
      .map_wrap{
         .contact_info{
            .tit::after{
               height: 3px;
               margin: 14px 0px;
            }
         }
      }
   }
   @media screen and (max-width:767px){
      .list{
         width: 33.3%;
      }
   }
`

const About = () => {

   // TopArea, Tab
   const subPageTab: SubPageTab[] = [
      { name: "본사" },
      { name: "부산지사" },
      { name: "광주지사" },
   ]

   const [tabIndex, setTabIndex] = useState(0);
   useEffect(() => {
      const tabItems = document.querySelectorAll(".tab_wrap>.list")
      tabItems.forEach((tabItem, index) => {
         tabItem.addEventListener("click", () => {
            setTabIndex(index)
         })
      })
   }, [])

   const tabData = [
      {
         mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3175.91568291543!2d127.04489077628557!3d37.24970794223166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b45efd7b8e52d%3A0x40b8917a6ec9dc2!2z7YWM7YGs7Yq466asIOyYge2GtSDsp4Dsi53sgrDsl4XshLw!5e0!3m2!1sko!2skr!4v1721567118134!5m2!1sko!2skr",
         add: "경기도 수원시 영통구 신원로 55 테크트리영통 지식산업센터 913~914호",
         tel: "031-234-2870",
      },
      {
         mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.2087995627376!2d129.04431617632582!3d35.15141305889549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3568eb0a68649d31%3A0xe5ccdfa71a89d85b!2z67aA7IKw6rSR7Jet7IucIOu2gOyCsOynhOq1rCDsl4TqtJHroZwzODbrsojquLggMzM!5e0!3m2!1sko!2skr!4v1723021490088!5m2!1sko!2skr",
         add: "부산광역시 부산진구 엄광로 386번길 33",
         tel: "070-8801-5411 ",
      },
      {
         mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3259.213647948395!2d126.84958497632822!3d35.2260512548431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35718e13fec5acbd%3A0x3845bbf1fbb0da4a!2z6rSR7KO86rSR7Jet7IucIOu2geq1rCDssqjri6jqs7zquLDroZwyMDjrsojquLggMTctMTUgMw!5e0!3m2!1sko!2skr!4v1723027470730!5m2!1sko!2skr",
         add: "광주광역시 북구 첨단과기로 208번길 17-15, 306호(오룡동)3",
         tel: "062-971-5688~9",
      },
   ]

   return (
      <Container className="xl:mb-120">
         <SubPageTabArea
            data={subPageTab}
         />
         <div className="map_wrap container">
            <div className="map">
               <iframe
                  id="iframe_map"
                  src={tabData[tabIndex].mapSrc}
                  style={{ border: "0" }}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="google map"
               />
            </div>
            <div className="contact_info_wrap wrap">
               <div className="contact_info">
                  <h4 className="tit xl:text-32 text-16 font-semibold">&#40;주&#41;우수조달컨설팅</h4>
                  <ul>
                     <li className="list_item">
                        <i className="xi-maker icon"></i>
                        <p className="txt xl:text-18 text-14 xl:leading-30 font-light">
                           {tabData[tabIndex].add}
                        </p>
                     </li>
                     <li className="list_item">
                        <i className="xi-call icon"></i>
                        <p className="txt xl:text-18 text-14">{tabData[tabIndex].tel}</p>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </Container >
   )
}

export default About