// components
import NoticeArea from "../../components/NoticeArea";

export default function Information(){

   return(
      <div>
         <NoticeArea />
      </div>
   )
}