import { useEffect } from "react"

const FooterNoMg = () => {
   useEffect(() => {
      const footer = document.getElementById('footer')
      if (footer) {
         footer.classList.add("on")
      }

      return () => {
         if (footer) {
            footer.classList.remove("on")
         }
      }
   }, [])
}

export default FooterNoMg;